<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="16">
          <span class="title-name">批量导入</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" inline label-width="110px" class="allForm">
          <el-form-item label="模板" :required="true">
            <el-button type="success" size="mini" @click="download">下载模板</el-button>
          </el-form-item>
        </el-form>
        <el-form ref="ruleForm" inline label-width="110px" class="allForm">
          <el-form-item label="所属分类" :required="true" class="formInput">
            <el-cascader
              v-model="form.category_id"
              :options="category"
              :props="{expandTrigger:'hover',value:'category_id',label:'name',leaf:'category_id',emitPath:false}"
              size="mini"
              class="input searchInput"
              :show-all-levels="false"
              filterable
              clearable
            />
          </el-form-item>
        </el-form>
        <el-form inline label-width="110px" class="allForm">
          <el-form-item label="上传文件" :required="true" class="formInput">
            <el-upload
              ref="upload"
              class="upload"
              action="#"
              :auto-upload="false"
              :limit="1"
              :on-change="exportFile"
              :on-remove="removeFile"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <vxe-toolbar>
          <template v-slot:buttons>
            <vxe-input v-model="filterName" type="search" placeholder="试试全表搜索" />
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          keep-source
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="400"
          :data="list"
          :edit-config="{trigger: 'click', mode: 'cell', showStatus: true, icon: 'fa fa-pencil-square-o'}"
        >
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="content_text"
            title="标题"
          />
          <vxe-table-column
            field="explain"
            title="解析"
          />
          <vxe-table-column
            field="options"
            title="选项"
          />
          <vxe-table-column
            field="correct"
            title="正确答案"
            :edit-render="{name: 'input'}"
          />
          <vxe-table-column title="操作" width="200">
            <template v-slot="{ row, rowIndex }">
              <template>
                <vxe-button status="primary" :loading="row.loading" @click="saveUpdate(row, 'name')">更新数据</vxe-button>
              </template>
            </template>
          </vxe-table-column>
        </vxe-table>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { rowStyle, headerStyle } from '@/utils/tableStyleJs'

  import { getName } from '@/api/category'
  import { transData } from '@/utils/handleJson'
  import { uploadAccept } from '@/utils/uploadAccept'
  import { addSS } from '@/api/subject'
  import XLSX from 'xlsx'
  import XEUtils from 'xe-utils'
  export default {
    name: 'BatchExport',
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        file: '',
        form: {
          category_id: '',
          options: [],
          subject: []
        },
        category: [],
        tableData: [],
        filterName: ''
      }
    },
    computed: {
      list(v) {
        const filterName = XEUtils.toString(this.filterName).trim().toLowerCase()
        if (filterName) {
          const filterRE = new RegExp(filterName, 'gi')
          const searchProps = ['content_text', 'explain']
          const rest = this.tableData.filter(item => searchProps.some(key => XEUtils.toString(item[key]).toLowerCase().indexOf(filterName) > -1))
          return rest.map(row => {
            const item = Object.assign({}, row)
            searchProps.forEach(key => {
              item[key] = XEUtils.toString(item[key]).replace(filterRE, match => `${match}`)
            })
            return item
          })
        }
        return this.tableData
      }
    },
    created() {
      this.getCategory()
    },
    methods: {
      rowStyle,
      headerStyle,
      // 下载模板
      download() {
        const header = [
          { key: 'content_text', title: '标题' },
          { key: 'explain', title: '解析' },
          { key: 'options', title: '选项' },
          { key: 'correct', title: '正确答案' }
        ]
        const content = [
          {
            content_text: '突发意外情况多，要求较高的安全意识是道路运输驾驶员的职业特点吗？',
            explain: '解析:正确答案为A与B。',
            options: 'A/用手帕包扎*B/用毛巾包扎*C/用棉质衣服包扎*D/用细绳缠绕包扎',
            correct: 'A:B'
          }
        ]
        ExportExcel(header, content, `多选题模板.xlsx`)
      },
      exportFile(file) {
        const fileSize = file.size / 1024 / 1024 < 500
        if (!fileSize) {
          this.$message.error('文件大小不能超过500MB！！！')
          return false
        }
        let flag = false
        flag = uploadAccept(file, '文档')
        if (flag) {
          this.file = file
          this.analysing(file)
        } else {
          this.file = ''
          this.$refs.upload.clearFiles()
        }
      },
      removeFile() {
        this.file = ''
        this.tableData = []
      },
      analysing(files) {
        const that = this
        const fileReader = new FileReader()
        fileReader.onload = (ev) => {
          try {
            const data = ev.target.result
            const workbook = XLSX.read(data, {
              type: 'binary'
            })
            const wsname = workbook.SheetNames[0]// 取第一张表
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])// 生成json表格内容
            that.form.options = []
            that.form.subject = []
            for (const i of ws) {
              that.tableData.push({
                content_text: i['标题'],
                explain: i['解析'],
                options: i['选项'].split('*'),
                correct: i['正确答案']
              })
              const optionsMeta = i['选项'].split('*')
              that.form.options.push({
                options: optionsMeta,
                correct: i['正确答案']
              })
              that.form.subject.push({
                content_text: i['标题'],
                explain: i['解析'],
                type: 2
              })
            }
          } catch (e) {
            this.$message.error('文件格式不正确！')
            this.$refs.upload.clearFiles()
            return false
          }
        }
        fileReader.readAsBinaryString(files.raw)
      },
      getCategory() {
        getName().then(res => {
          if (res.data.code === 1) {
            this.category = transData(res.data.content, 'category_id', 'p_id', 'children')
          }
        })
      },
      saveUpdate(row, field) {
        const xTable = this.$refs.table
        if (xTable.isUpdateByRow(row)) {
          row.loading = true
          this.submitSave(row).then(data => {
            // 局部保存，并将行数据恢复到初始状态（如果 record 为空则不改动行数据，只恢复状态）
            xTable.reloadRow(row, null, field)
            this.$message.success('保存成功！')
            row.loading = false
          })
        } else {
          this.$message.info('数据未改动！')
        }
      },
      submitSave(row) {
        return new Promise(resolve => {
          const rest = {
            date3: XEUtils.toDateString(new Date())
          }
          if (row.correct) {
            rest.correct = row.correct
          }
          setTimeout(() => resolve(rest), 500)
        })
      },
      // 提交添加
      commit() {
        if (this.$textNull(this.form.category_id)) {
          this.$message.error('请选择分类！')
          return
        } else if (this.$textNull(this.file)) {
          this.$message.error('请上传文件！')
          return
        }
        const data = this.form.options
        for (const i in data) {
          const options = []
          for (const j of data[i].options) {
            const arr = j.split('/')
            options.push({
              title: arr[0],
              body: arr[1],
              correct: 0
            })
          }
          const correct = data[i].correct.split(':')
          for (const k of correct) {
            for (const h in options) {
 if (options[h].title === k) {
              options[h].correct = 1
              break
            }
}
          }
          this.form.subject[i].subjectOptions = options
          this.form.subject[i].category_id = this.form.category_id
        }
        addSS(this.form.subject).then(res => {
                if (res.data.code === 1) {
                  this.$message.success(res.data.msg)
                  this.close()
                  this.$emit('get-list')
                } else {
                  this.$message.error(res.data.msg)
                }
              })
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
