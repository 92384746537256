<template>
  <div class="">
    <Operate
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <BatchExport
      v-if="dialog.refresh2"
      :dialog.sync="dialog.show2"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <!--    <el-card>-->
    <!--      <el-row type="flex" justify="space-between" :gutter="50">-->
    <!--        <el-col>-->
    <!--          <span class="text-primary text-pad-right">分类</span>-->
    <!--          <el-cascader-->
    <!--            v-model="condition.category_id"-->
    <!--            :options="category"-->
    <!--            :props="{expandTrigger:'hover',value:'category_id',label:'name',leaf:'category_id',emitPath:false}"-->
    <!--            size="mini"-->
    <!--            class="input searchInput"-->
    <!--            :show-all-levels="false"-->
    <!--            filterable-->
    <!--            clearable-->
    <!--            @change="changeCondition"-->
    <!--          />-->
    <!--        </el-col>-->
    <!--        <el-col>-->
    <!--          <span class="text-primary text-pad-right">状态</span>-->
    <!--          <el-select-->
    <!--            v-model="condition.status"-->
    <!--            filterable-->
    <!--            clearable-->
    <!--            size="mini"-->
    <!--            class="input searchInput"-->
    <!--            @change="changeCondition"-->
    <!--          >-->
    <!--            <el-option :value="2" label="全部" />-->
    <!--            <el-option :value="1" label="是" />-->
    <!--            <el-option :value="0" label="否" />-->
    <!--          </el-select>-->
    <!--        </el-col>-->
    <!--        <el-col>-->
    <!--          <el-input-->
    <!--            v-model="condition.search"-->
    <!--            class="input searchInput"-->
    <!--            size="mini"-->
    <!--            suffix-icon="el-icon-search"-->
    <!--            placeholder="搜索"-->
    <!--            @input="searchInput"-->
    <!--          />-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </el-card>-->
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <el-row type="flex" justify="between">
              <el-col>
                <el-button type="primary" size="mini" @click="openDialog({title:'添加试题',addOr:'add'})">添加试题</el-button>
                <el-button type="warning" size="mini" @click="openBatch({del:'batch'})">批量导入</el-button>
                <el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
              </el-col>
              <el-col :span="1">
                <vxe-button @click="getList">刷新</vxe-button>
              </el-col>
            </el-row>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="job_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="checkbox" width="60" fixed="left" />
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="content_text"
            title="标题"
          />
          <vxe-table-column
            field="name"
            title="分类"
          />
          <vxe-table-column
            field="explain"
            title="解析"
          />
          <vxe-table-column
            title="是否启用"
          >
            <template v-slot="{ row, rowIndex }">
              <el-switch
                ref="switch"
                v-model="row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="(v)=>{changeStatus(v,row)}"
              />
            </template>
          </vxe-table-column>
          <vxe-table-column field="" title="操作">
            <template v-slot="{ row, rowIndex }">
              <el-dropdown size="small" type="primary">
                <el-button size="small" type="primary">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改试题',addOr:'upd'},row)">修改
                  </el-dropdown-item>
                  <el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </vxe-table-column>
        </vxe-table>
        <div style="height: 20px" />
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
  import { getName } from '@/api/category'
  import Operate from './Operate'
  import BatchExport from './BatchExport'
  import { transData } from '@/utils/handleJson'
  import { getSubjectList, delSubject, updSubjectStatus } from '@/api/companySubject'
  export default {
    name: 'Index',
    components: {
      Operate,
      BatchExport
    },
    props: {
      condition: ''
    },
    data() {
      return {
        loading: false,
        condition: {
          category_id: null,
          search: '',
          status: 2
        },
        table: {
          list: [],
          total: 0,
          currentPage: 1,
          pageSize: 10,
          sortName: '',
          sortBy: ''
        },
        dialog: {
          row: Object,
          info: {},
          refresh: false, // 每次刷新DOM
          refresh2: false, // 每次刷新DOM
          show: false,
          show2: false
        },
        category: []
      }
    },
    watch: {
      condition: {
        handler(v) {
          this.table.currentPage = 1
          this.getList()
        },
        deep: true
      }
    },
    created() {
      this.getList()
      this.getCategory()
    },
    methods: {
      rowStyle,
      headerStyle,
      getCategory() {
        getName().then(res => {
          if (res.data.code === 1) {
            this.category = transData(res.data.content, 'category_id', 'p_id', 'children')
          }
        })
      },
      // 打开dialog
      openDialog(info, row) {
        this.dialog.refresh = true
        this.dialog.show = true
        this.dialog.info = info
        this.dialog.row = row
      },
      openBatch() {
        this.dialog.refresh2 = true
        this.dialog.show2 = true
      },
      searchInput(v) {
        this.table.currentPage = 1
        this.getList()
      },
      changeCondition() {
        this.table.currentPage = 1
        this.getList()
      },
      changeStatus(v, row) {
        updSubjectStatus({
          com_subject_id: row.com_subject_id,
          status: v
        }).then(res => {
          if (res.data.code === 1) {
            this.$message.success(res.data.msg)
            this.getList()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      getList() {
        this.loading = true
        getSubjectList({
          type: 1,
          com_category_id: this.condition.com_category_id,
          status: this.condition.status2,
          searchName: this.condition.search2,
          currentPage: this.table.currentPage,
          pageSize: this.table.pageSize
        }).then(res => {
          if (res.data.code === 1) {
            this.table.list = res.data.content
            this.table.total = Number(res.data.totalElements)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      },
      remove(info, row) {
        const id = []
        if (info.del === 'batch') {
          const arr = this.$refs.table.getCheckboxRecords()
          const arr1 = this.$refs.table.getCheckboxReserveRecords()
          const batchDel = arr.concat(arr1)
          for (const i of batchDel) {
            id.push(i.com_subject_id)
          }
          if (id.length === 0) {
            this.$message.error('请勾选！！！')
            return
          }
        } else {
          id.push(row.com_subject_id)
        }
        const a = ac => {
          if (ac === 'confirm') {
            delSubject({
              items: id.join()
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.success(res.data.msg)
                this.$refs.table.clearCheckboxRow()
                this.$refs.table.clearCheckboxReserve()
                this.getList()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.info('取消了删除！！！')
          }
        }
        this.$topicMsg('确认删除吗！！！', a)
      },
      handleSizeChange(pageSize) {
        this.table.pageSize = pageSize
        this.table.currentPage = 1
        this.getList()
      },
      handleCurrentChange(currentPage) {
        this.table.currentPage = currentPage
        this.getList()
      },
      refreshDialog() {
        this.dialog.refresh = false
        this.dialog.refresh2 = false
      }
    }
  }
</script>

<style scoped>

</style>
