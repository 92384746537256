<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="用户姓名" :required="true">
            <el-input v-model="form.user_name" class="formInput" readonly="" />
          </el-form-item>
          <el-form-item label="手机号" :required="true">
            <el-input v-model="form.telephone" class="formInput" readonly maxlength="11" />
          </el-form-item>
          <el-form-item label="身份证号" :required="true">
            <el-input v-model="form.id_card" class="formInput" readonly maxlength="18" />
          </el-form-item>
          <el-form-item label="性别" :required="true" class="formInput">
            <el-select v-model="form.sex" class="w-100">
              <el-option :value="0" label="全部" />
              <el-option :value="1" label="男" />
              <el-option :value="2" label="女" />
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input v-model="form.car_num" class="formInput" maxlength="8" />
          </el-form-item>
          <el-form-item label="公司" :required="true" class="formInput">
            <el-select v-model="form.company" value-key="company_id" class="w-100" filterable @change="changeCompany">
              <el-option v-for="(v,i) in company" :key="v.company_id" :value="v" :label="v.company_name" />
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门" class="formInput">
            <el-cascader
              ref="dept"
              v-model="form.dept"
              :options="dept"
              :props="{expandTrigger:'hover',value:'dept_id',label:'dept_name',leaf:'dept_id',emitPath:true,checkStrictly: true }"
              :show-all-levels="false"
              class="w-100"
              filterable
              clearable
            />
          </el-form-item>
          <el-form-item label="岗位" class="formInput" :required="true">
            <el-select v-model="form.station" value-key="stationId" class="w-100" filterable>
              <el-option v-for="(v,i) in station" :key="v.stationId" :value="v" :label="v.abbreviation" />
            </el-select>
          </el-form-item>
          <el-form-item label="人员标签" class="formInput">
            <el-select v-model="form.job_id" filterable class="w-100">
              <el-option v-for="(v,i) in job" :key="v.job_id" :value="v.job_id" :label="v.job_name" />
            </el-select>
          </el-form-item>
          <el-form-item label="关联课程" :required="true" class="formInput">
            <el-select v-model="form.is_course" class="w-100">
              <el-option :value="1" label="是" />
              <el-option :value="0" label="否" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getDeptByCompany } from '@/api/dept'
  import { transData } from '@/utils/handleJson'
  import { getStationList } from '@/api/station'
  export default {
    name: 'Operate',
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        form: {
          user_name: '',
          telephone: '',
          id_card: '',
          station: '',
          car_num: '',
          sex: 1,
          job_id: '',
          company: '',
          dept: '',
          is_course: 0
        },
        company: [],
        job: [],
        dept: []
      }
    },
    created() {
      this.upd()
      this.getCompanyByRegion()
      this.getStationList()
    },
    methods: {
      upd() {
          this.form.user_name = this.row.user_name
          this.form.telephone = this.row.telephone
          this.form.id_card = this.row.id_card
          this.form.car_num = this.row.car_num
          this.form.station = { stationId: this.row.station_id }
          this.getDeptByCompany(this.row.company_id)
          this.form.sex = this.row.sex
      },
      getStationList() {
        getStationList().then(res => {
          this.station = res.data
        })
      },
      getCompanyByRegion() {
        getCompanyByRegion({
          region_id: this.$loginInfo.region_id,
          role_g_id: this.$loginInfo.role_g_id,
          region_level: this.$loginInfo.region_level
        }).then(res => {
          if (res.data.code === 1) {
            this.company = res.data.content
          }
        })
      },
      getAllJob(v) {
        getAllJob({
          company_id: v
        }).then(res => {
          if (res.data.code === 1) {
            this.job = res.data.content
          }
        }).catch(e => {
        })
      },
      getDeptByCompany(v) {
        getDeptByCompany({
          company_id: v,
          dept_id: this.$loginInfo.master_level == 0 ? this.$loginInfo.dept_id : null
        }).then(res => {
          if (res.data.code === 1) {
            if (!this.$textNull(this.$loginInfo.dept_p_id)) {
              res.data.push({
                dept_id: this.$loginInfo.dept_id,
                p_id: this.$loginInfo.dept_p_id,
                dept_name: this.$loginInfo.dept_name
              })
            }
            this.dept = transData(res.data, 'dept_id', 'p_id', 'children')
          }
        }).catch(e => {
        })
      },
      changeCompany(v) {
        this.getAllJob(v.company_id)
        this.getDeptByCompany(v.company_id)
      },
      // 提交添加
      commit() {
        if (this.$textNull(this.form.company)) {
          this.$message.error('请选择公司！')
          return
        }
        let dept_id = 0
        const dept_name = []
        if (!this.$textNull(this.form.dept) && this.form.dept != 0) {
          const dept = this.$refs.dept.getCheckedNodes()
          const data = dept[0].pathNodes
          for (const i of data) {
            dept_name.push(i.label)
            dept_id = i.value
          }
        }
             entryUser(null, {
               user_id: this.row.user_id,
               company_id: this.form.company.company_id,
               dept_id: dept_id,
               dept_name: dept_name.join('/'),
               job_id: this.form.job_id,
               car_num: this.form.car_num,
               stationId: this.form.station.stationId,
               status: this.form.is_course
             }).then(res => {
               if (res.data.code === 1) {
                 this.$message.success(res.data.msg)
                 this.close()
                 this.$emit('get-list')
               } else {
                 this.$message.error(res.data.msg)
               }
             })
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
