import request from '@/utils/request'
const src = 'menu'
// 获取所有的菜单
export function getAllMenu(data) {
  return request({
    url: src + '/getAllMenu',
    method: 'get',
    params: data
  })
}

// 获取用户拥有的菜单
export function getMenu(data) {
  return request({
    url: src + '/getMenu',
    method: 'get',
    params: data
  })
}

// 获取一级菜单
export function getFirstMenu(data) {
  return request({
    url: src + '/getFirstMenu',
    method: 'get',
    params: data
  })
}

