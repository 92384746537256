<template>
  <el-dialog
    :visible.sync="show"
    width="50%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="设置标题" :required="true" class="formInput">
            <el-input v-model="form.content_text" type="textarea" :autosize="{ minRows: 4}" />
            <!--<wang :content-back="form.content_text" :disabled="info.addOr === 'view'" @get-content="getContent" />-->
          </el-form-item>
          <el-form-item label="试题解析" :required="true" class="formInput">
            <el-input v-model="form.explain" type="textarea" :autosize="{ minRows: 4}" />
          </el-form-item>
          <el-form-item label="所属分类" :required="true" class="formInput">
            <el-cascader
              ref="category"
              v-model="form.category_id"
              :options="category"
              :props="{expandTrigger:'hover',value:'category_id',label:'name',leaf:'category_id',emitPath:false}"
              :show-all-levels="false"
              class="w-100"
              filterable
              clearable
            />
          </el-form-item>
          <el-form-item label="试题选项" :required="true">
            <el-input v-for="(v, i) in radioList" :key="i" v-model="v.name" :disabled="info.addOr==='view'" placeholder="请输入内容">
              <template slot="prepend">
                <el-radio v-model="radio" :label="v.id" :disabled="info.addOr==='view'">{{ radioToUni(v.id) }}</el-radio>
              </template>
              <template v-if="info.addOr!=='view'" slot="append">
                <el-button v-if="v.id === 65" icon="el-icon-plus" @click="addOption" />
                <el-button v-else-if="v.id > 65" icon="el-icon-delete" @click="removeOption(i)" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="正确选项">
            <span class="text-primary">{{ radioToUni(radio) }}</span>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addS, updateSubject, getSubjectOption } from '@/api/subject'
  import { getName } from '@/api/category'
  import { transData } from '@/utils/handleJson'
  import wang from '@/components/WangEditor/index'
  export default {
    name: 'Operate',
    components: {
      wang
    },
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        form: {
          content_text: '',
          explain: '',
          category_id: null,
          options: []
        },
        category: [],
        radioList: [
          { id: 65, name: '', correct: 1 }
        ],
        radio: 65
      }
    },
    created() {
      this.upd()
      this.getCategory()
    },
    methods: {
      upd() {
        if (this.info.addOr === 'upd') {
          this.form.content_text = this.row.content_text
          this.form.explain = this.row.explain
          this.form.category_id = this.row.category_id
          this.getSubjectOption()
        }
      },
      getCategory() {
        getName().then(res => {
          if (res.data.code === 1) {
            this.category = transData(res.data.content, 'category_id', 'p_id', 'children')
          }
        })
      },
      getSubjectOption() {
        getSubjectOption({
          subject_id: this.row.subject_id
        }).then(res => {
          if (res.data.code === 1) {
            this.radioList = []
            const data = res.data
            for (const i of data) {
              this.radioList.push({
                id: i.title.charCodeAt(),
                name: i.body,
                correct: i.correct
              })
              if (i.correct === 1) {
                this.radio = i.title.charCodeAt()
              }
            }
          }
        })
      },
      getContent(v) {
        this.form.content_text = v.noHtml
      },
      // 转数字为字母
      radioToUni(v) {
        return String.fromCharCode(v)
      },
      // 增加选项
      addOption() {
        this.radioList.push({ id: this.radioList[this.radioList.length - 1].id + 1, name: '' })
      },
      // 删除选项
      removeOption(i) {
        this.radioList.splice(i, 1)
        if (this.radioList.length === 1) {
          this.radio = 65
        }
        this.radioList = this.radioList.map((v, i, e) => {
          return { id: 65 + i, name: v.name }
        })
      },
      // 提交添加
      commit() {
        if (this.$textNull(this.form.content_text)) {
          this.$message.error('请填写标题！')
          return
        } else if (this.$textNull(this.form.explain)) {
          this.$message.error('请填写解析！')
          return
        } else if (this.$textNull(this.form.category_id)) {
          this.$message.error('请选择分类！')
          return
        }
        const options = []
        for (const i of this.radioList) {
          if (this.$textNull(i.name)) {
            this.$message.error('请填写选项内容！')
            return
          }
          options.push({
            title: this.radioToUni(i.id),
            body: i.name,
            correct: i.id === this.radio ? 1 : 0
          })
        }
        const category = this.$refs.category.getCheckedNodes(true)
        if (this.info.addOr === 'add') {
          addS(options, {
            content_text: this.form.content_text,
            explain: this.form.explain,
            category_id: category[0].value,
            type: 1
          }).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.close()
              this.$emit('get-list')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          updateSubject(options, {
            subject_id: this.row.subject_id,
            content_text: this.form.content_text,
            explain: this.form.explain,
            category_id: this.form.category_id,
            type: 1
          }).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.close()
              this.$emit('get-list')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
