import request from '@/utils/request'
const src = 'master'
// 查询登录名是否重复
export function selectLogin(data) {
  return request({
    url: src + '/selectLogin',
    method: 'get',
    params: data
  })
}

// 查询此管理员账号信息
export function getMasterMsg() {
  return request({
    url: src + '/msg',
    method: 'get'
  })
}

// 获得管理员账号
export function getMaster(data) {
  return request({
    url: src + '/getMaster',
    method: 'get',
    params: data
  })
}

// 添加管理员
export function addMaster(data, param) {
  return request({
    url: src + '/addMaster',
    method: 'POST',
    data,
    params: param
  })
}

// 修改管理员
export function updateMaster(data, param) {
  return request({
    url: src + '/updateMaster',
    method: 'PUT',
    data,
    params: param
  })
}

// 删除管理员
export function deleteMaster(data, param) {
  return request({
    url: src + '/deleteMaster',
    method: 'DELETE',
    data,
    params: param
  })
}

// 添加组织与角色关联
export function addMasterIdentity(data, param) {
  return request({
    url: src + '/addMasterIdentity',
    method: 'POST',
    data,
    params: param
  })
}

// 删除组织与角色关联
export function deleteMasterIdentity(data, param) {
  return request({
    url: src + '/deleteMasterIdentity',
    method: 'DELETE',
    data,
    params: param
  })
}

// 查询组织角色表根据管理员ID
export function getMasterIdentity(data, param) {
  return request({
    url: src + '/getMasterIdentity',
    method: 'GET',
    data,
    params: param
  })
}

