<template>
  <div class="dashboard-container">
    <Operate
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <el-card>
      <el-row type="flex" justify="space-between" :gutter="50">
        <el-col>
          <span class="text-primary text-pad-right">性别</span>
          <el-select v-model="condition.sex" size="mini" class="input searchInput" @change="changeSex">
            <el-option :value="0" label="全部" />
            <el-option :value="1" label="男" />
            <el-option :value="2" label="女" />
          </el-select>
        </el-col>
        <el-col>
          <span class="text-primary text-pad-right">公司</span>
          <el-select v-model="condition.company_id" class="input searchInput" filterable clearable size="mini" @change="changeCompany">
            <el-option v-for="(v,i) in company" :key="i" :value="v.company_id" :label="v.company_name" />
          </el-select>
        </el-col>
        <el-col>
          <span class="text-primary text-pad-right">岗位</span>
          <el-select v-model="condition.job_id" class="input searchInput" clearable size="mini" @change="changeJob">
            <el-option v-for="(v,i) in job" :key="i" :value="v.job_id" :label="v.job_name" />
          </el-select>
        </el-col>
        <el-col>
          <el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索" @input="searchInput" />
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <vxe-button @click="getList">刷新</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="company_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="checkbox" width="60" fixed="left" />
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="user_name"
            title="用户姓名"
          />
          <vxe-table-column
            title="性别"
          >
            <template v-slot="{ row, rowIndex }">
              <span v-if="row.sex===1">男</span>
              <span v-else>女</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="telephone"
            title="手机号"
          />
          <vxe-table-column field="id_card" title="身份证" />
          <vxe-table-column field="region_name" title="组织区域" />
          <vxe-table-column
            field="company_name"
            title="公司名称"
          />
          <vxe-table-column
            field="car_num"
            title="车牌号"
          />
          <vxe-table-column
            field="station_name"
            title="岗位"
          />
          <vxe-table-column
            field="job_name"
            title="人员标签"
          />
          <vxe-table-column
            field="dept_name"
            title="所属部门"
          />
          <vxe-table-column
            field="entry_time"
            title="入职时间"
          />
          <vxe-table-column
            field="resignation_time"
            title="离职时间"
          />
          <vxe-table-column field="" title="操作">
            <template v-slot="{ row, rowIndex }">
              <el-dropdown size="small" type="primary">
                <el-button size="small" type="primary">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'入职人员',addOr:'entry'},row)">入职</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-remove" @click.native="resetUser({title:'注销',del:'phone'},row)">注销手机身份证</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </vxe-table-column>
        </vxe-table>
        <div style="height: 20px" />
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
  import Operate from './Operate'
  export default {
    name: 'Index',
    components: {
      Operate
    },
    data() {
      return {
        loading: false,
        condition: {
          sex: 0,
          company_id: null,
          job_id: null,
          search: ''
        },
        table: {
          list: [],
          total: 0,
          currentPage: 1,
          pageSize: 10,
          sortName: '',
          sortBy: ''
        },
        dialog: {
          row: Object,
          info: {},
          refresh: false, // 每次刷新DOM
          refresh2: false, // 每次刷新DOM
          show: false,
          show2: false
        },
        company: [],
        job: [],
        loginInfo: {}
      }
    },
    created() {
      this.getList()
      this.getCompanyByRegion()
    },
    methods: {
      rowStyle,
      headerStyle,
      getCompanyByRegion() {
        getCompanyByRegion({
          region_id: this.$loginInfo.region_id,
          role_g_id: this.$loginInfo.role_g_id,
          region_level: this.$loginInfo.region_level
        }).then(res => {
          if (res.data.code === 1) {
            this.company = res.data.content
          }
        })
      },
      getAllJob(v) {
        getAllJob({
          company_id: v
        }).then(res => {
          if (res.data.code === 1) {
            this.job = res.data.content
          }
        }).catch(e => {
        })
      },

      // 打开dialog
      openDialog(info, row) {
        this.dialog.refresh = true
        this.dialog.show = true
        this.dialog.info = info
        this.dialog.row = row
      },
      // 打开批量导入
      openExport() {
        this.dialog.refresh2 = true
        this.dialog.show2 = true
      },
      searchInput(v) {
        this.table.currentPage = 1
        this.getList()
      },
      changeSex() {
        this.table.currentPage = 1
        this.getList()
      },
      changeCompany(v) {
        this.table.currentPage = 1
        this.getAllJob(v)
        this.getList()
      },
      changeJob() {
        this.table.currentPage = 1
        this.getList()
      },
      getList() {
        this.loading = true
        getAllLeaveUser({
          sex: this.condition.sex,
          region_id: this.$loginInfo.region_id,
          company_id: this.condition.company_id,
          job_id: this.condition.job_id,
          searchName: this.condition.search,
          currentPage: this.table.currentPage,
          pageSize: this.table.pageSize
        }).then(res => {
          if (res.data.code === 1) {
            this.table.list = res.data.content
            this.table.total = Number(res.data.totalElements)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      },
      resetUser(info, row) {
        const a = ac => {
          if (ac === 'confirm') {
            removePhoneAndCard(null, {
              user_id: row.user_id
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.success(res.data.msg)
                this.getList()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.info('取消了注销！！！')
          }
        }
        this.$topicMsg('确认注销吗！！！', a)
      },
      handleSizeChange(pageSize) {
        this.table.pageSize = pageSize
        this.table.currentPage = 1
        this.getList()
      },
      handleCurrentChange(currentPage) {
        this.table.currentPage = currentPage
        this.getList()
      },
      refreshDialog() {
        this.dialog.refresh = false
      }
    }
  }
</script>

<style scoped>

</style>
