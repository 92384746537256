<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-card>
      <el-row type="flex" justify="space-between" :gutter="50">
        <el-col>
          <el-button type="primary" size="mini" @click="changeStatusAll(1)">全是</el-button>
          <el-button type="danger" size="mini" @click="changeStatusAll(0)">全否</el-button>
          <el-button type="success" size="mini" @click="changeStatusCurr(1)">当前是</el-button>
          <el-button type="warning" size="mini" @click="changeStatusCurr(0)">当前否</el-button>
        </el-col>
        <!--        <el-col>-->
        <!--          <el-button round size="mini">公司</el-button>-->
        <!--          <el-select v-model="condition.company_id" class="input searchInput" filterable clearable size="mini" @change="changeCondition">-->
        <!--            <el-option v-for="(v,i) in company" :key="i" :value="v.company_id" :label="v.company_name" />-->
        <!--          </el-select>-->
        <!--        </el-col>-->
        <el-col>
          <el-input
            v-model="condition.search"
            class="input searchInput"
            size="mini"
            suffix-icon="el-icon-search"
            placeholder="搜索"
            @input="searchInput"
          />
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <vxe-button @click="getList">刷新</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="notice_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="checkbox" width="60" fixed="left" />
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="user_name"
            title="姓名"
          />
          <vxe-table-column
            field="telephone"
            title="电话号码"
          />
          <vxe-table-column
            field="id_card"
            title="身份证号码"
          />
          <!--          <vxe-table-column-->
          <!--            field="region_name"-->
          <!--            title="组织名称"-->
          <!--          />-->
          <!--          <vxe-table-column-->
          <!--            field="company_name"-->
          <!--            title="公司名称"-->
          <!--          />-->
          <vxe-table-column title="设置关联">
            <template v-slot="{ row, rowIndex }">
              <el-switch
                ref="switch"
                v-model="row.courseUserStatus"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="(v)=>{changeStatus(v,row)}"
              />
            </template>
          </vxe-table-column>
        </vxe-table>
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </el-dialog>
</template>

<script>
  import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
  import { getUserByCourseAndCompany } from '@/api/person'
  import { UpdUserCourse } from '@/api/companyCourse'
  import { getUserByCompany } from '@/api/appComCourse'
  export default {
    name: 'SetUser',
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {
        } // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {
        } // 这样可以指定默认的值
      }
    },
    data() {
      return {
        loading: false,
        show: this.dialog,
        condition: {
          search: '',
          company_id: null
        },
        table: {
          list: [],
          total: 0,
          currentPage: 1,
          pageSize: 10,
          sortName: '',
          sortBy: ''
        },
        company: []
      }
    },
    created() {
      this.getList()
    },
    methods: {
      rowStyle,
      headerStyle,
      searchInput(v) {
        this.getList()
      },
      changeCondition() {
        this.table.currentPage = 1
        this.getList()
      },
      getList() {
        this.loading = true
        getUserByCompany({
          company_id: this.row.company_id,
          course_id: this.row.com_course_id,
          searchName: this.condition.search,
          currentPage: this.table.currentPage,
          pageSize: this.table.pageSize
        }).then(res => {
          if (res.data.code === 1) {
            this.table.list = res.data.content
            this.table.total = Number(res.data.totalElements)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      },
      handleSizeChange(pageSize) {
        this.table.pageSize = pageSize
        this.table.currentPage = 1
        this.getList()
      },
      handleCurrentChange(currentPage) {
        this.table.currentPage = currentPage
        this.getList()
      },
      changeStatus(v, row) {
        UpdUserCourse(null, {
          status: v,
          course_id: this.row.com_course_id,
          user_id: row.user_id,
          company_id: row.company_id
        }).then(res => {
          if (res.data.code === 1) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
          this.loading = false
        })
      },
      changeStatusCurr(v) {
        const id = []
        const arr = this.table.list
        for (const i of arr) {
          id.push(i.user_id)
        }
        UpdUserCourse(id, {
          status: v,
          user_id: id.join(),
          course_id: this.row.com_course_id,
          company_id: this.row.company_id
        }).then(res => {
          if (res.data.code === 1) {
            this.$message.success(res.data.msg)
            this.getList()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      changeStatusAll(v) {
        const loading = this.$loading({
          lock: true,
          text: '正在设置请稍后！！！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        UpdUserCourse(null, {
            status: v,
            user_id: [],
            course_id: this.row.com_course_id,
            region_id: this.row.region_id,
            company_id: this.row.company_id,
            flag: 'all'
          }).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.getList()
            } else {
              this.$message.error(res.data.msg)
            }
            loading.close()
          })
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
