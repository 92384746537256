<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <NextCourse
      v-if="next.refresh"
      :dialog.sync="next.show"
      :info="next.info"
      @refresh="refreshDialog"
    />
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm" :disabled="info.addOr==='view'">
          <el-form-item label="课程名称" :required="true">
            <el-input v-model="form.title" class="formInput" />
          </el-form-item>
          <el-form-item label="学习有效期" :required="true" class="formInput">
            <el-date-picker
              v-model="form.datetime"
              type="daterange"
              class="formInput w-100"
              :unlink-panels="true"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00','23:59:59']"
            />
          </el-form-item>
          <!--          <el-form-item label="培训类型" :required="true" class="formInput">-->
          <!--            <el-select v-model="form.cultivate_type" class="w-100">-->
          <!--              <el-option :value="1" label="岗前培训" />-->
          <!--              <el-option :value="2" label="安全培训" />-->
          <!--              <el-option :value="3" label="继续教育" />-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="课程类型" :required="true" class="formInput">-->
          <!--            <el-select v-model="form.course_type" class="w-100">-->
          <!--              <el-option :value="1" label="安全培训" />-->
          <!--              <el-option :value="2" label="临时学习" />-->
          <!--              <el-option :value="3" label="违规学习" />-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="培训时长" :required="true">-->
          <!--            <el-input v-model="form.title" class="formInput" />-->
          <!--          </el-form-item>-->
          <el-form-item label="所属企业" :required="true" class="formInput">
            <el-select v-model="form.company" value-key="company_id" filterable class="w-100">
              <el-option v-for="(v,i) in companies" :key="v.company_id" :value="v" :label="v.company_name" />
            </el-select>
          </el-form-item>
          <el-form-item label="课程封面" :required="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              action="#"
              list-type="picture"
              :file-list="form.fileList"
              :auto-upload="false"
              :limit="1"
              :on-change="change"
            >
              <i class="el-icon-receiving" style="font-size: 80px;" />
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>，只支持传一张图片</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="课程介绍" :required="true">
            <el-input v-model="form.content" class="formInput" type="textarea" :autosize="{ minRows: 4}" />
          </el-form-item>
          <!--          <el-form-item label="课程备注">-->
          <!--            <el-input v-model="form.remark" class="formInput" type="textarea" :autosize="{ minRows: 4}" />-->
          <!--          </el-form-item>-->
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">下一步</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addCourse, UpdCourse } from '@/api/companyCourse'
  import { uploadAccept } from '@/utils/uploadAccept/index'
  import { getAllDistrict } from '@/api/region'
  import NextCourse from './NextCourse'
  import { getAllCompanies } from '@/api/company'
  export default {
    name: 'Operate',
    components: {
      NextCourse
    },
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        next: {
          show: false,
          refresh: false,
          info: {}
        },
        form: {
          title: '',
          company: '',
          entry_time: '',
          currt_encoder: 1,
          train_encoder: 1,
          content: '',
          remark: '',
          file: '',
          datetime: '',
          fileList: []
        },
        region: [],
        companies: []
      }
    },
    created() {
      this.upd()
      this.getAllCompanies()
    },
    methods: {
      upd() {
        if (this.info.addOr !== 'add') {
          this.form.title = this.row.title
          this.form.datetime = [this.row.entry_time, this.row.failure_time]
          this.form.region = { region_id: this.row.region_id, region_name: this.row.region_name }
          this.form.company = { company_id: this.row.company_id, company_name: this.row.company_name }
          this.form.cultivate_type = this.row.cultivate_type
          this.form.course_type = this.row.course_type
          this.form.content = this.row.content
          this.form.remark = this.row.remark
          this.form.file = this.row.img_path
          this.form.fileList.push({ url: this.$srcUrl + this.row.img_path, name: this.$srcUrl + this.row.img_path })
        }
      },
      change(file, fileList) {
        const fileSize = file.size / 1024 / 1024 < 500
        if (!fileSize) {
          this.$message.error('文件大小不能超过500MB！！！')
          return false
        }
        let flag = false
        flag = uploadAccept(file, '图片')
        if (flag) {
          this.form.file = file
        } else {
          fileList.splice(-1, 1)
        }
      },
      getAllCompanies() {
        getAllCompanies().then(res => {
          if (res.data.code === 1) {
            this.companies = res.data.content
          }
        })
      },
      getMonthDay(v) {
        let day = 0
        switch (v) {
          case 2: {
            const flag = (year % 4 == 0 && year % 100 != 100) || year % 400 == 0
            if (flag) {
              day = 29
            } else {
              day = 28
            }
            break
          }
          case 4: {
            day = 30
            break
          }
          case 6: {
            day = 30
            break
          }
          case 9: {
            day = 30
            break
          }
          case 11: {
            day = 30
            break
          }
          case 1: {
            day = 31
            break
          }
          case 3: {
            day = 31
            break
          }
          case 5: {
            day = 31
            break
          }
          case 7: {
            day = 31
            break
          }
          case 8: {
            day = 31
            break
          }
          case 10: {
            day = 31
            break
          }
          case 12: {
            day = 31
            break
          }
        }
        return day
      },
      // 提交添加
      commit() {
        const form = new FormData()
        if (this.info.addOr !== 'view') {
          if (this.$textNull(this.form.title)) {
            this.$message.error('请填写课程名称！')
            return
          } else if (this.$textNull(this.form.datetime)) {
            this.$message.error('请选择学习有效期！')
            return
          } else if (this.$textNull(this.form.company)) {
            this.$message.error('请选择所属企业！')
            return
          } else if (this.$textNull(this.form.file)) {
            this.$message.error('请选择课程封面！')
            return
          } else if (this.$textNull(this.form.content)) {
            this.$message.error('请填写课程介绍！')
            return
          }
          this.form.entry_time = this.form.datetime[0]
          this.form.failure_time = this.form.datetime[1]
          form.append('title', this.form.title)
          form.append('company_id', this.form.company.company_id)
          form.append('company_name', this.form.company.company_name)
          form.append('entry_time', this.form.entry_time)
          form.append('failure_time', this.form.failure_time)
          form.append('currt_encoder', this.form.currt_encoder)
          form.append('train_encoder', this.form.train_encoder)
          form.append('content', this.form.content)
          form.append('creator', this.$loginInfo.master_id)
          form.append('myFile', this.form.file.raw)
        }
        if (this.info.addOr === 'add') {
          addCourse(form).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.next.info = {
                course_id: res.data.com_course_id,
                company_id: this.form.company.company_id,
                video_img: res.data.img_path
              }
              this.next.show = true
              this.next.refresh = true
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else if (this.info.addOr === 'upd') {
          form.append('com_course_id', this.row.com_course_id)
          UpdCourse(form).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.next.info = {
                course_id: this.row.com_course_id,
                company_id: this.form.company.company_id,
                video_img: this.row.img_path
              }
              this.next.show = true
              this.next.refresh = true
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.next.info = {
            course_id: this.row.com_course_id,
            company_id: this.form.company.company_id,
            video_img: this.row.img_path,
            addOr: 'view'
          }
          this.next.show = true
          this.next.refresh = true
        }
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      },
      refreshDialog() {
        this.next.refresh = false
        this.close()
        this.$emit('get-list')
      }
    }
  }
</script>

<style scoped>

</style>
