<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="地区名称" :required="true">
            <el-input v-model="form.region_name" class="formInput" />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addRegion, updateRegion } from '@/api/region'
  export default {
    name: 'Operate',
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        form: {
          name: ''
        }
      }
    },
    created() {
      this.upd()
    },
    methods: {
      upd() {
        if (this.info.addOr === 'upd') {
          this.form.region_name = this.row.region_name
        }
      },
      // 提交添加
      commit() {
        if (this.$textNull(this.form.region_name)) {
          this.$message.error('请填写地区名称！')
          return
        }
        if (this.info.addOr === 'addRoot') {
          addRegion(null, {
            region_name: this.form.region_name,
            region_level: 1
          }).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.close()
              this.$emit('get-list')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else if (this.info.addOr === 'addSon') {
          addRegion(null, {
            region_name: this.form.region_name,
            p_id: this.row.region_id,
            region_level: this.row.region_level + 1
          }).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.close()
              this.$emit('get-list')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          updateRegion(null, {
            region_id: this.row.region_id,
            region_name: this.form.region_name
          }).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.close()
              this.$emit('get-list')
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
