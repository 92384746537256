import request from '@/utils/request'
const src = 'station'
// 查询岗位列表
export function getStationList(data) {
  return request({
    url: src + '/getList',
    method: 'get',
    params: data
  })
}
