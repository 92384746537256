<template>
  <div class="dashboard-container">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="标准课程" name="标准课程">
        <el-row type="flex" justify="space-between" :gutter="50">
          <el-col>
            <span class="text-primary text-pad-right">分类</span>
            <el-cascader
              v-model="condition.category_id"
              :options="category"
              :props="{expandTrigger:'hover',value:'category_id',label:'name',leaf:'category_id',emitPath:false}"
              size="mini"
              class="input searchInput"
              :show-all-levels="false"
              filterable
              clearable
              @change="changeCondition"
            />
          </el-col>
          <el-col>
            <span class="text-primary text-pad-right">状态</span>
            <el-select
              v-model="condition.status"
              filterable
              size="mini"
              class="input searchInput"
              @change="changeCondition"
            >
              <el-option :value="2" label="全部" />
              <el-option :value="1" label="是" />
              <el-option :value="0" label="否" />
            </el-select>
          </el-col>
          <el-col>
            <el-input
              v-model="condition.search"
              class="input searchInput"
              size="mini"
              clearable
              suffix-icon="el-icon-search"
              placeholder="搜索"
              @input="changeCondition"
            />
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="企业培训" name="企业培训">
        <el-row type="flex" justify="space-between" :gutter="50">
          <el-col>
            <span class="text-primary text-pad-right">分类</span>
            <el-cascader
              v-model="condition.com_category_id"
              :options="category"
              :props="{expandTrigger:'hover',value:'category_id',label:'name',leaf:'category_id',emitPath:false}"
              size="mini"
              class="input searchInput"
              :show-all-levels="false"
              filterable
              clearable
              @change="changeCondition"
            />
          </el-col>
          <el-col>
            <span class="text-primary text-pad-right">状态</span>
            <el-select
              v-model="condition.status2"
              filterable
              size="mini"
              class="input searchInput"
              @change="changeCondition"
            >
              <el-option :value="2" label="全部" />
              <el-option :value="1" label="是" />
              <el-option :value="0" label="否" />
            </el-select>
          </el-col>
          <el-col>
            <el-input
              v-model="condition.search2"
              class="input searchInput"
              size="mini"
              clearable
              suffix-icon="el-icon-search"
              placeholder="搜索"
              @input="changeCondition"
            />
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <Standard v-if="activeName==='标准课程'" :condition="condition" />
    <Company v-else-if="activeName==='企业培训'" :condition="condition" />
  </div>
</template>

<script>
import Standard from './standardSingle'
import Company from './companySingle'
import { getName } from '@/api/category'
import { transData } from '@/utils/handleJson'

export default {
  name: 'Index',
  components: {
    Standard, Company
  },
  data() {
    return {
      activeName: '标准课程',
      condition: {
        category_id: null,
        com_category_id: null,
        search1: '',
        search2: '',
        status: 2,
        status2: 2
      },
      category: []
    }
  },
  created() {
    this.getCategory()
  },
  methods: {
    changeCondition() {
      this.getList()
    },
    getCategory() {
      getName().then(res => {
        if (res.data.code === 1) {
          this.category = transData(res.data.content, 'category_id', 'p_id', 'children')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
