<template>
  <el-dialog
    :visible.sync="show"
    width="80%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <AddMaster
      v-if="addMaster.refresh"
      :dialog.sync="addMaster.show"
      :row="addMaster.row"
      :info="addMaster.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-card>
      <el-row type="flex" justify="space-between" :gutter="50">
        <el-col>
          <el-button type="primary" size="mini" @click="openDialog({title:'添加管理员账号',addOr:'add'},row)">添加管理员账号</el-button>
        </el-col>
        <el-col>
          <el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索" @input="searchInput" />
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <vxe-button @click="getList">刷新</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="job_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="user_name"
            title="用户名称"
          />
          <vxe-table-column
            field="login_name"
            title="登录名"
          />
          <vxe-table-column
            field="dept_name"
            title="所属组织"
          />
          <vxe-table-column
            field="identity_name"
            title="所属角色"
          />
          <vxe-table-column
            field="telephone"
            title="手机号"
          />
          <vxe-table-column
            field="create_time"
            title="创建时间"
          />
          <vxe-table-column field="" title="操作">
            <template v-slot="{ row, rowIndex }">
              <el-dropdown size="small" type="primary">
                <el-button size="small" type="primary">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改',addOr:'upd'},row)">修改</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </vxe-table-column>
        </vxe-table>
        <div style="height: 20px" />
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </el-dialog>
</template>

<script>
  import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
  import { getMasterByCom } from '@/api/dept'
  import { deleteMaster } from '@/api/master'
  import AddMaster from './AddMaster'
  export default {
    name: 'ViewAccount',
    components: {
      AddMaster
    },
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        loading: false,
        condition: {
          search: ''
        },
        table: {
          list: [],
          total: 0,
          currentPage: 1,
          pageSize: 10,
          sortName: '',
          sortBy: ''
        },
        addMaster: {
          row: Object,
          info: {},
          refresh: false, // 每次刷新DOM
          show: false
        }
      }
    },
    created() {
      this.getList()
    },
    methods: {
      rowStyle,
      headerStyle,
      // 打开dialog
      // 打开dialog
      openDialog(info, row) {
        this.addMaster.refresh = true
        this.addMaster.show = true
        this.addMaster.info = info
        this.addMaster.row = row
      },
      searchInput(v) {
        this.getList()
      },
      changeCondition() {
        this.getList()
      },
      getList() {
        this.loading = true
          getMasterByCom({
            identity_name: this.condition.search,
            company_id: this.row.company_id,
            p_id: this.row.master_id,
            currentPage: this.table.currentPage,
            pageSize: this.table.pageSize
          }).then(res => {
            if (res.data.code === 1) {
              this.table.list = res.data.content
              this.table.total = Number(res.data.totalElements)
            }
            this.loading = false
          }).catch(e => {
            this.loading = false
          })
      },
      remove(info, row) {
        const id = []
        if (info.del === 'batch') {
          const arr = this.$refs.table.getCheckboxRecords()
          const arr1 = this.$refs.table.getCheckboxReserveRecords()
          const batchDel = arr.concat(arr1)
          for (const i of batchDel) {
            id.push(i.master_id)
          }
          if (id.length === 0) {
            this.$message.error('请勾选！！！')
            return
          }
        } else {
          id.push(row.master_id)
        }
        const a = ac => {
          if (ac === 'confirm') {
            deleteMaster(null, {
              master_id: id.join()
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.success(res.data.msg)
                this.$refs.table.clearCheckboxRow()
                this.$refs.table.clearCheckboxReserve()
                this.getList()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.info('取消了删除！！！')
          }
        }
        this.$topicMsg('确认删除吗！！！', a)
      },
      handleSizeChange(pageSize) {
        this.table.pageSize = pageSize
        this.table.currentPage = 1
        this.getList()
      },
      handleCurrentChange(currentPage) {
        this.table.currentPage = currentPage
        this.getList()
      },
      refreshDialog() {
        this.addMaster.refresh = false
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
