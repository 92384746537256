<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="用户名称" :required="true">
            <el-input v-model="form.user_name" class="formInput" />
          </el-form-item>
          <el-form-item label="登录名称" :required="true">
            <el-input v-model="form.login_name" class="formInput" />
          </el-form-item>
          <el-form-item label="手机号" :required="true">
            <el-input v-model="form.telephone" class="formInput" maxlength="11" />
          </el-form-item>
          <el-form-item label="角色" :required="true" class="formInput">
            <el-select v-model="form.role" value-key="i_id" class="w-100" filterable="">
              <el-option v-for="(v,i) in role" :key="v.i_id" :value="v" :label="v.identity_name" />
            </el-select>
          </el-form-item>
          <el-form-item label="分配组织" :required="true" class="formInput">
            <el-cascader
              ref="dept"
              v-model="form.dept"
              :options="dept"
              :props="{expandTrigger:'hover',value:'dept_id',label:'dept_name',leaf:'dept_id',emitPath:true,checkStrictly: true }"
              :show-all-levels="false"
              class="w-100"
              filterable
              clearable
            />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getDeptByCompany, addUserOfDept, updUserOfDept } from '@/api/dept'
  import { selectLogin, addMasterIdentity, deleteMasterIdentity } from '@/api/master'
  import { textTel } from '@/utils/validated'
  import { transData } from '@/utils/handleJson'
  import { selectIdentityByCompanyId } from '@/api/person'
  export default {
    name: 'Operate',
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        form: {
          user_name: '',
          login_name: '',
          email: '',
          telephone: '',
          company: '',
          dept: '',
          role: '',
          role_g_id: 3
        },
        dept: [],
        role: []
      }
    },
    created() {
      this.upd()
      this.getDeptByCompany()
      this.selectIdentityByCompanyId()
    },
    methods: {
      upd() {
        if (this.info.addOr === 'upd') {
          this.form.user_name = this.row.user_name
          this.form.login_name = this.row.login_name
          this.form.email = this.row.email
          this.form.telephone = this.row.telephone
          this.form.dept = this.row.dept_id
          this.form.role = { i_id: this.row.role_id }
        }
      },
      getDeptByCompany() {
        getDeptByCompany({
          company_id: this.row.company_id
        }).then(res => {
          if (res.data.code === 1) {
            this.dept = transData(res.data, 'dept_id', 'p_id', 'children')
          }
        }).catch(e => {
        })
      },
      selectIdentityByCompanyId() {
        selectIdentityByCompanyId({
          company_id: this.row.company_id
        }).then(res => {
          if (res.data.code === 1) {
            this.role = res.data.content
          }
        }).catch(e => {
        })
      },
      // 提交添加
      async commit() {
        if (this.$textNull(this.form.user_name)) {
          this.$message.error('请填写用户名称！')
          return
        } else if (this.$textNull(this.form.login_name)) {
          this.$message.error('请填写登录名称！')
          return
        } else if (this.$textNull(this.form.telephone)) {
          this.$message.error('请填写手机号！')
          return
        } else if (textTel(this.form.telephone)) {
          this.$message.error('请填写正确手机号格式！')
          return
        } else if (this.$textNull(this.form.role)) {
          this.$message.error('请选择角色！')
          return
        }
        let dept_id = 0
        let dept_p_id = 0
        const dept_name = []
        if (!this.$textNull(this.form.dept) && this.form.dept != 0) {
          const dept = this.$refs.dept.getCheckedNodes()
          const data = dept[0].pathNodes
          for (const i of data) {
            dept_name.push(i.label)
            dept_id = i.value
          }
          if (data.length > 1) {
            dept_p_id = data[data.length - 2].value
          }
        }
        const form = {
          user_name: this.form.user_name,
          login_name: this.form.login_name,
          telephone: this.form.telephone,
          dept_id: dept_id,
          dept_p_id: dept_p_id,
          dept_name: dept_name.join('/'),
          company_id: this.$loginInfo.role_g_id != 3 ? this.row.company_id : this.$loginInfo.company_id,
          region_id: this.$loginInfo.role_g_id != 3 ? this.row.region_id : this.$loginInfo.region_id,
          region_name: this.$loginInfo.role_g_id != 3 ? this.row.region_name : this.$loginInfo.region_name,
          creator_id: this.$loginInfo.master_id,
          role_id: this.form.role.i_id,
          p_id: this.$loginInfo.role_g_id != 3 ? this.row.master_id : this.$loginInfo.master_id
        }
        const parent = {
          identity_id: this.form.role.i_id,
          master_id: this.row.master_id
        }
        if (this.info.addOr === 'add') {
          const result = new Promise((resolve) => {
            selectLogin({
              login_name: this.form.login_name
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.error('账号已被注册！')
              } else {
              }
              resolve(res)
            })
          })
          result.then(res => {
            if (res.data.code === 0) {
              addUserOfDept(null, form).then(res => {
                if (res.data.code === 1) {
                  parent.master_id = res.data
                  addMasterIdentity(null, parent).then(res => {
                    if (res.data.code === 1) {
                      this.$message.success(res.data.msg)
                      this.close()
                      this.$emit('get-list')
                    } else {
                      this.$message.error(res.data.msg)
                    }
                  })
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          })
        } else {
          form.master_id = this.row.master_id
          if (this.form.login_name === this.row.login_name) {
            const data = await new Promise((r, j) => {
              deleteMasterIdentity(null, {
                identity_id: this.row.role_id,
                master_id: this.row.master_id
              }).then(res => {
                r(res)
              })
            })
            addMasterIdentity(null, parent).then(res => {
              updUserOfDept(null, form).then(res => {
                if (res.data.code === 1) {
                  this.$message.success(res.data.msg)
                  this.close()
                  this.$emit('get-list')
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            })
          } else {
            const result = new Promise((resolve) => {
              selectLogin({
                login_name: this.form.login_name
              }).then(res => {
                if (res.data.code === 1) {
                  this.$message.error('账号已被注册！')
                } else {
                }
                resolve(res)
              })
            })
            result.then(res => {
              if (res.data.code === 0) {
                const data = new Promise((r, j) => {
                  deleteMasterIdentity(null, {
                    identity_id: this.row.role_id,
                    master_id: this.row.master_id
                  }).then(res => {
                    r(res)
                  })
                })
                addMasterIdentity(null, parent).then(res => {
                  updUserOfDept(null, form).then(res => {
                    if (res.data.code === 1) {
                      this.$message.success(res.data.msg)
                      this.close()
                      this.$emit('get-list')
                    } else {
                      this.$message.error(res.data.msg)
                    }
                  })
                })
              }
            })
          }
        }
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
