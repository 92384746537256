<template>
  <div class="dashboard-container">
    <el-card>
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col>
          <span class="text-primary text-pad-right">日期</span>
          <el-date-picker
            v-model="condition.date"
            type="daterange"
            class="input searchInput"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            :unlink-panels="true"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            size="mini"
            @change="changeCondition"
          />
        </el-col>
        <el-col>
          <el-input
            v-model="condition.searchName"
            class="input searchInput"
            size="mini"
            suffix-icon="el-icon-search"
            placeholder="搜索"
            @input="changeCondition"
          />
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <el-row type="flex" justify="between">
              <el-col :span="1">
                <vxe-button @click="getList">刷新</vxe-button>
              </el-col>
            </el-row>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="notice_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="checkbox" width="60" fixed="left" />
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="userName"
            title="操作用户"
          />
          <vxe-table-column
            field="createtime"
            title="时间"
            formatter="toDateString"
          />
          <vxe-table-column
            field="action"
            title="操作描述"
          />
          <vxe-table-column
            field="environment"
            title="客户端"
          >
            <template v-slot="{row}">
              <span v-if="row.environment===0">APP客户端</span>
              <span v-else-if="row.environment===1">web客户端</span>
              <span v-else>web管理端</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="ipaddr"
            title="IP"
          />
        </vxe-table>
        <div style="height: 20px" />
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
import { allUserLog } from '@/api/userLog'

export default {
  name: 'Index',
  components: {},
  data() {
    return {
      loading: false,
      condition: {
        is_release: 2,
        searchName: '',
        date: '',
        sTime: '',
        eTime: ''
      },
      table: {
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        sortName: '',
        sortBy: ''
      },
      dialog: {
        row: Object,
        info: {},
        refresh: false, // 每次刷新DOM
        refresh2: false, // 每次刷新DOM
        show: false,
        show2: false
      },
      company: [],
      loginInfo: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    rowStyle,
    headerStyle,
    changeCondition() {
      this.table.currentPage = 1
      this.getList()
    },
    getList() {
      this.loading = true
      allUserLog({
        searchName: this.condition.searchName,
        sTime: this.condition.date ? this.condition.date[0] : '',
        eTime: this.condition.date ? this.condition.date[1] : '',
        currentPage: this.table.currentPage,
        pageSize: this.table.pageSize
      }).then(res => {
        if (res.data.code === 1) {
          this.table.list = res.data.content
          this.table.total = Number(res.data.totalElements)
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleSizeChange(pageSize) {
      this.table.pageSize = pageSize
      this.table.currentPage = 1
      this.getList()
    },
    handleCurrentChange(currentPage) {
      this.table.currentPage = currentPage
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
