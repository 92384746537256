import request from '@/utils/request'
const src = 'category'
// 所属分类全量查询
export function getName(param) {
  return request({
    url: src + '/getName',
    method: 'get',
    params: param
  })
}

// 添加分类
export function addCategory(data, param) {
  return request({
    url: src + '/addCategory',
    method: 'POST',
    data,
    params: param
  })
}

// 更新分类
export function updateCategory(data, param) {
  return request({
    url: src + '/updateCategory',
    method: 'PUT',
    data,
    params: param
  })
}

// 删除分类
export function deleteCategory(data, param) {
  return request({
    url: src + '/deleteCategory',
    method: 'DELETE',
    data,
    params: param
  })
}
