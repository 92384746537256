import request from '@/utils/request'
const src = 'app/com/Course'
// 根据公司ID和课程ID获取用户
export function getUserByCompany(param) {
  return request({
    url: src + '/getUserByCompany',
    method: 'get',
    params: param
  })
}
