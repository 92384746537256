import request from '@/utils/request'
const src = 'userLog'
// 获取管理员操作日志
export function allUserLog(param) {
  return request({
    url: src + '/allUserLog',
    method: 'get',
    params: param
  })
}
