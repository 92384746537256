import request from '@/utils/request'
const src = 'com_subject'

// 查询公司题目
export function getSubjectList(params) {
  return request({
    url: src + '/bank',
    method: 'get',
    params
  })
}

// 根据题目ID获取题目详情
export function getSubjectDetail(params) {
  return request({
    url: src + '/change',
    method: 'put',
    params
  })
}

// 根据题目ID获取题目详情
export function updSubject(data, params) {
  return request({
    url: src + '/update',
    method: 'put',
    data,
    params: params
  })
}

// 更改题目状态
export function updSubjectStatus(params) {
  return request({
    url: src + '/status',
    method: 'put',
    params
  })
}

// 删除公司题目
export function delSubject(params) {
  return request({
    url: src + '/det',
    method: 'delete',
    params
  })
}

// 添加公司题目
export function addSubject(data, params) {
  return request({
    url: src + '/addS',
    method: 'post',
    data,
    params: params

  })
}

// 批量添加公司题目
export function addSubjectList(data, params) {
  return request({
    url: src + '/addSS',
    method: 'post',
    data,
    params: params
  })
}

// 添加课程时查询的题目
export function getSubjectForCourse(params) {
  return request({
    url: src + '/name',
    method: 'get',
    params
  })
}
