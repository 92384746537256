<template>
  <div class="">
    <Operate
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <el-row type="flex" justify="between">
              <el-col>
                <el-button type="primary" size="mini" @click="openDialog({title:'添加视频',addOr:'add'})">添加视频</el-button>
                <el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
              </el-col>
              <el-col :span="1">
                <vxe-button @click="getList">刷新</vxe-button>
              </el-col>
            </el-row>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="job_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="checkbox" width="60" fixed="left" />
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="video_name"
            title="视频标题"
          />
          <vxe-table-column
            field="video_time"
            title="视频时长"
          />
          <vxe-table-column
            field="teacher_name"
            title="授课老师"
          />
          <vxe-table-column
            field="category_name"
            title="分类"
          />
          <vxe-table-column
            field="video_img"
            title="视频封面"
          >
            <template v-slot="{ row, rowIndex }">
              <img :src="$srcUrl.substr(0,$srcUrl.length-1)+row.video_img" alt="" style="width: 100px;">
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="introduce"
            title="视频简介"
          />
          <vxe-table-column field="" title="操作">
            <template v-slot="{ row, rowIndex }">
              <el-dropdown size="small" type="primary">
                <el-button size="small" type="primary">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改视频',addOr:'upd'},row)">修改
                  </el-dropdown-item>
                  <el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </vxe-table-column>
        </vxe-table>
        <div style="height: 20px" />
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
import { allVideo, detVideo, detVideoImg } from '@/api/video'
import Operate from './Operate'
import { transData } from '@/utils/handleJson'

export default {
  name: 'Index',
  components: {
    Operate
  },
  props: {
    condition: ''
  },
  data() {
    return {
      loading: false,
      condition: {
        category_id: null,
        search: '',
        status: 2
      },
      table: {
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        sortName: '',
        sortBy: ''
      },
      dialog: {
        row: Object,
        info: {},
        refresh: false, // 每次刷新DOM
        show: false
      },
      category: []
    }
  },
  watch: {
    condition: {
      handler(v) {
        this.table.currentPage = 1
        this.getList()
      },
      deep: true
    }
  },
  created() {
    this.getList()
  },
  methods: {
    rowStyle,
    headerStyle,
    // 打开dialog
    openDialog(info, row) {
      this.dialog.refresh = true
      this.dialog.show = true
      this.dialog.info = info
      this.dialog.row = row
    },
    openBatch() {
      this.dialog.refresh2 = true
      this.dialog.show2 = true
    },
    searchInput(v) {
      this.table.currentPage = 1
      this.getList()
    },
    changeCondition() {
      this.table.currentPage = 1
      this.getList()
    },
    getList() {
      this.loading = true
      allVideo({
        is_recommend: 2,
        is_public: 2,
        is_convert: 2,
        category_id: this.condition.category_id,
        searchName: this.condition.search1,
        currentPage: this.table.currentPage,
        pageSize: this.table.pageSize
      }).then(res => {
        if (res.data.code === 1) {
          this.table.list = res.data.content
          this.table.total = Number(res.data.totalElements)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false
      })
    },
    remove(info, row) {
      const id = []
      const img = []
      if (info.del === 'batch') {
        const arr = this.$refs.table.getCheckboxRecords()
        const arr1 = this.$refs.table.getCheckboxReserveRecords()
        const batchDel = arr.concat(arr1)
        for (const i of batchDel) {
          id.push(i.video_id)
          img.push(i.video_img)
        }
        if (id.length === 0) {
          this.$message.error('请勾选！！！')
          return
        }
      } else {
        id.push(row.video_id)
        img.push(row.video_img)
      }
      const a = ac => {
        if (ac === 'confirm') {
          detVideo(null, {
            video_id: id.join(),
            img_path: img.join()
          }).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.$refs.table.clearCheckboxRow()
              this.$refs.table.clearCheckboxReserve()
              this.getList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.info('取消了删除！！！')
        }
      }
      this.$topicMsg('确认删除吗！！！', a)
    },
    handleSizeChange(pageSize) {
      this.table.pageSize = pageSize
      this.table.currentPage = 1
      this.getList()
    },
    handleCurrentChange(currentPage) {
      this.table.currentPage = currentPage
      this.getList()
    },
    refreshDialog() {
      this.dialog.refresh = false
    }
  }
}
</script>

<style scoped>

</style>
