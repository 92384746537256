<template>
  <el-dialog
    :visible.sync="show"
    width="45%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div style="text-align: center;font-size: 20px;font-weight: bolder">
      请拨打  400-010-6800  热线，联系客户服务人员开具发票
    </div>
  </el-dialog>
</template>

<script>
export default {
name: 'Receipt',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog
    }
  },
  methods: {
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
