<template>
  <div class="dashboard-container">
    <el-card style="margin-top: 20px;">
      <div slot="title" class="header-title">
        <el-row :gutter="5">
          <el-col :span="24">
            <!--          <span class="title-name">{{ info.title }}</span>-->
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="0">
        <el-col :span="24">
          <div>
            <span>课程简介：</span>
            <span>{{ $route.query.content }}</span>
          </div>
          <div>
            <div>
              <swiper
                ref="swiperTop"
                :options="optionsTop"
                style="width: 800px;height: 400px"
                @slide-change-transition-start="changeTop"
                @mouseenter.native="showVideo"
                @mouseleave.native="hideVideo"
              >
                <swiper-slide v-for="(v,i) in videoList" :key="i">
                  <div class="w-100 h-100 position-relative" style="z-index: 5">
                    <!--                    <img :src="urls[i]" alt="" class="w-100 h-100">-->
                    <video :src="v.video_path" controls="controls" class="w-100 h-100" />

                    <div class="video-info" :class="{'video-info-show':showVideoInfo===false}">
                      <div>{{ v.video_name }}</div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div>
              <swiper
                ref="swiperThumb"
                :options="optionsThumb"
                style="width: 800px;height: 140px"
                @click="clickThumb"
              >
                <swiper-slide v-for="(v,i) in videoList" :key="i">
                  <img :src="$srcUrl.substr(0,$srcUrl.length-1)+v.video_img" alt="" class="w-100 h-100 img-base" :class="{'img-active':swiperIndex===i}">
                </swiper-slide>
                <div slot="pagination" class="swiper-pagination" />
              </swiper>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <span>考试题：</span>
          <div v-for="(v, i) in subjectList" :key="i">
            <div v-for="(j, k) in v.subjects" :key="k" class="mt-2">
              <div>{{ k+1 }}、{{ j.contentText }}</div>
              <div
                v-for="(r, l) in j.subjectOptionVos"
                :key="l"
              >
                <span>{{ r.title }}：</span>
                <span>{{ r.body }}</span>
              </div>
              <div>正确答案：{{j.explain}}</div>
            </div>

          </div>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button type="info" @click="close">关闭</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { CourseMsg, getMsgByCourseId } from '@/api/course'
export default {
  name: 'Operate',
  components: {
    Swiper, SwiperSlide
  },
  directives: {
    swiper: directive
  },
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        title: '',
        content: '',
        contentNoHtml: '',
        fileList: [],
        file: '',
        href: ''
      },
      company: [],
      optionsThumb: {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true
      },
      optionsTop: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      videoList: [],
      swiperIndex: 0,
      radioList: [
        { id: 65, name: 'A', correct: 1 },
        { id: 65, name: 'B', correct: 1 },
        { id: 65, name: 'C', correct: 1 },
        { id: 65, name: 'D', correct: 1 }
      ],
      radio: 65,
      showVideoInfo: false,
      subjectList: []
    }
  },
  computed: {
    swiperTop() {
      return this.$refs.swiperTop.$swiper
    },
    swiperThumb() {
      return this.$refs.swiperThumb.$swiper
    }
  },
  created() {
    this.CourseMsg()
    this.getMsgByCourseId()
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperIndex = this.swiperTop.activeIndex
    })
  },
  methods: {
    CourseMsg() {
      CourseMsg({
        course_id: this.$route.query.course_id
      }).then(res => {
        this.videoList = res.data
      })
    },
    getMsgByCourseId() {
      getMsgByCourseId({
        courseId: this.$route.query.course_id
      }).then(res => {
        this.subjectList = res.data
      })
    },
    changeTop(el) {
      this.swiperIndex = el.activeIndex
      this.swiperThumb.slideTo(el.activeIndex, 500, false)
    },
    clickThumb(el) {
      this.swiperIndex = el.clickedIndex
      this.swiperTop.slideTo(el.clickedIndex, 500, false)
    },
    showVideo() {
      this.showVideoInfo = true
    },
    hideVideo() {
      this.showVideoInfo = false
    },
    next() {
      let index = 0
      index++
      this.swiperIndex = index
      if (index >= this.videoList.length - 1) {
        return
      }
      this.swiperTop.slideTo(index, 500, false)
      this.swiperThumb.slideTo(index, 500, false)
    },
    prev() {
      let index = this.swiperIndex
      index--
      if (index === this.videoList.length - 1) {
        return
      }
      this.swiperTop.slideTo(index, 500, false)
      this.swiperThumb.slideTo(index, 500, false)
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.swiper-container {
  --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
  --swiper-navigation-color: #00ff33; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px; /* 设置按钮大小 */
}

.img-base {
  opacity: 0.8;
}

.img-active {
  opacity: 1;
}

.video-info {
  position: absolute;
  width: 100%;
  background-color: rgba(233, 233, 233, .5);
  top: -3px;
  z-index: 3;
}
.video-info-show{
  display: none;
}
</style>
