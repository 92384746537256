import request from '@/utils/request'
const src = 'com_category'

// 添加公司分类
export function addCategory(params) {
  return request({
    url: src + '/addCategory',
    method: 'post',
    params
  })
}

// 删除公司分类
export function delCategory(params) {
  return request({
    url: src + '/deleteCategory',
    method: 'delete',
    params
  })
}

// 查询公司分类
export function getCategoryList(params) {
  return request({
    url: src + '/getName',
    method: 'get',
    params
  })
}

// 查询公司分类
export function updCategory(params) {
  return request({
    url: src + '/updateCategory',
    method: 'put',
    params
  })
}
