<template>
    <div id="login_box" v-if="show">
        <div
            style="
                position: fixed;
                bottom: 10px;
                color: #ffffff;
                font-size: 14px;
            "
        >
            Copyright 2009-2021 www.zdwy.online All rights reserved版权所有
            <span @click="openUrlW" style="cursor: pointer"
                >蜀ICP备16034933号-2</span
            >
        </div>
        <div id="logo">
            <img src="@/assets/newLogin/gs-logo.png" />
        </div>
        <div id="login_layer_box">
            <div
                v-if="!loading"
                id="btn_box"
                @click="handleLogin"
                @keyup.enter="handleLogin"
            >
                <span id="btn">登录</span>
            </div>
            <div v-else id="btn_box">
                <span>登录中，请稍后...</span>
            </div>
            <div id="login_layer">
                <div id="login_title_box">
                    <img src="@/assets/newLogin/eye.png" />
                    <span>远程教育学习管理系统</span>
                </div>
                <div id="login_input_box">
                    <div id="prompt_box">
                        <div id="prompt_box_content" />
                        <div class="test" />
                    </div>
                    <div class="inp_box">
                        <img src="@/assets/newLogin/zh.png" />
                        <input
                            id="userName"
                            v-model="loginForm.username"
                            type="text"
                            name=""
                            value=""
                            placeholder="请输入用户名"
                            autocomplete="off"
                        />
                    </div>
                    <div class="inp_box">
                        <img src="@/assets/newLogin/mm.png" />
                        <input
                            id="password"
                            v-model="loginForm.password"
                            :type="!passwordShow ? `password` : `type`"
                            name=""
                            value=""
                            placeholder="请输入密码"
                        />
                        <img
                            src="@/assets/biyang.png"
                            @click="passwordShow = !passwordShow"
                            v-show="!passwordShow"
                        />
                        <img
                            src="@/assets/zhenyang.png"
                            @click="passwordShow = !passwordShow"
                            v-show="passwordShow"
                        />
                    </div>
                    <div class="flex" style="margin: 20px 22px 0 22px">
                        <img
                            :src="imgCode"
                            alt=""
                            style="width: 300px; height: 50px"
                            @click="getLoginCode"
                        />
                        <input
                            v-model="loginForm.code"
                            style="
                                margin-top: 8px;
                                height: 35px;
                                width: 150px;
                                font-size: 22px;
                                background: none;
                                border: 1px solid gainsboro;
                                outline: none;
                            "
                            placeholder="请输入验证码"
                        />
                    </div>
                </div>
                <div id="login_bottom_bg" />
            </div>
        </div>
    </div>
</template>

<script>
import { getLoginCode } from "@/api";

export default {
    name: "Login",
    created() {
        if (window.location.hash.split("?")[1]) {
            this.show = false;
            const arr = window.location.hash.split("?");
            console.log(arr);
            const uuidArr = arr[1].split("=") || [];

            if (uuidArr[0] == "uuid") {
                this.$api
                    .get(
                        `/oauth/server/escort/rpc/online/xg/token?uuid=${uuidArr[1]}`
                    )
                    .then((res) => {
                        sessionStorage.setItem("Authorization", res.data.token);

                        this.$axiosReq(
                            "/oauth/server/escort/web/currentUser",
                            null,
                            {},
                            "get"
                        ).then((res) => {
                            // console.log(res);
                            this.$store.dispatch("master/loginAuto", res);
                        });
                    });
            } else {
                sessionStorage.setItem(
                    "Authorization",
                    window.location.hash.split("?")[1]
                );
                this.$axiosReq(
                    "/oauth/server/escort/web/currentUser",
                    null,
                    {},
                    "get"
                ).then((res) => {
                    // console.log(res);
                    this.$store.dispatch("master/loginAuto", res);
                });
            }
        }

        if (process.env.VUE_APP_ENV.trim() === "development") {
            this.loginForm.username = "superAdmin";
            this.loginForm.password = "zdwy@123456";
        }
        this.keyupEnter();
        this.getLoginCode();
    },
    data() {
        return {
            passwordShow: false,

            show: true,

            loginForm: {
                username: "",
                password: "",
                code: "",
                uuid: "",
            },
            loginRules: {
                // username: [{ required: true, trigger: 'blur', validator: validateUsername }],
                // password: [{ required: true, trigger: 'blur', validator: validatePassword }]
            },
            loading: false,
            passwordType: "password",
            redirect: undefined,
            imgCode: "",
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },

    methods: {
        openUrlW() {
            window.open("https://beian.miit.gov.cn");
        },
        keyupEnter() {
            // document.onkeydown = e => {
            //   if (e.keyCode === 13 && (e.target.baseURI === 'https://zdwy.online:10003/#/login' || 'http://zdwy.online:10003/#/')) {
            //     this.handleLogin()
            //   }
            // }
        },
        getLoginCode() {
            this.$axiosReq(getLoginCode, null, null, "get").then((res) => {
                this.imgCode = "data:image/png;base64," + res.data.img;
                this.loginForm.uuid = res.data.uuid;
            });
        },
        showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "";
            } else {
                this.passwordType = "password";
            }
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        },
        handleLogin() {
            if (this.$textNull(this.loginForm.username)) {
                this.$message.error("请输入登录名称！！！");
                return;
            } else if (this.$textNull(this.loginForm.password)) {
                this.$message.error("请输入登录密码！！！");
                return;
            } else if (this.$textNull(this.loginForm.code)) {
                this.$message.error("请输入验证码！！！");
                return;
            }
            this.loading = true;

            const login = this.$store.dispatch("master/login", this.loginForm);
            login
                .then((res) => {
                    this.$store.dispatch("tagsView/delAllViews");
                    this.loading = false;
                    if (res.data.userDetail.sysCompanyVo.areaName) {
                        var cityNameAll =
                            res.data.userDetail.sysCompanyVo.provinceName +
                            "/" +
                            res.data.userDetail.sysCompanyVo.cityName +
                            "/" +
                            res.data.userDetail.sysCompanyVo.areaName;
                        localStorage.setItem(
                            "cityNameAll",
                            JSON.stringify(cityNameAll)
                        );
                    } else if (res.data.userDetail.sysCompanyVo.cityName) {
                        var cityNameAll =
                            res.data.userDetail.sysCompanyVo.provinceName +
                            "/" +
                            res.data.userDetail.sysCompanyVo.cityName;
                        localStorage.setItem(
                            "cityNameAll",
                            JSON.stringify(cityNameAll)
                        );
                    } else {
                        var cityNameAll =
                            res.data.userDetail.sysCompanyVo.provinceName;
                        localStorage.setItem(
                            "cityNameAll",
                            JSON.stringify(cityNameAll)
                        );
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.getLoginCode();
                    this.loginForm.code = "";
                });
        },
    },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
        color: $cursor;
    }
}

/* reset element-ui css */
.login-container {
    .el-input {
        display: inline-block;
        height: 47px;
        width: 85%;

        input {
            background: transparent;
            border: 0px;
            -webkit-appearance: none;
            border-radius: 0px;
            padding: 12px 5px 12px 15px;
            color: $light_gray;
            height: 47px;
            caret-color: $cursor;

            &:-webkit-autofill {
                box-shadow: 0 0 0px 1000px $bg inset !important;
                -webkit-text-fill-color: $cursor !important;
            }
        }
    }

    .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #454545;
    }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
    min-height: 100%;
    width: 100%;
    background-color: $bg;
    overflow: hidden;

    .login-form {
        position: relative;
        width: 520px;
        max-width: 100%;
        padding: 160px 35px 0;
        margin: 0 auto;
        overflow: hidden;
    }

    .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;

        span {
            &:first-of-type {
                margin-right: 16px;
            }
        }
    }

    .svg-container {
        padding: 6px 5px 6px 15px;
        color: $dark_gray;
        vertical-align: middle;
        width: 30px;
        display: inline-block;
    }

    .title-container {
        position: relative;

        .title {
            font-size: 26px;
            color: $light_gray;
            margin: 0px auto 40px auto;
            text-align: center;
            font-weight: bold;
        }
    }

    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: $dark_gray;
        cursor: pointer;
        user-select: none;
    }
}

#login_box {
    width: 100%;
    height: 100%;
    background: url(~@/assets/newLogin/bg.jpg) no-repeat center;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#logo {
    position: absolute;
    width: 260px;
    height: 87px;
    top: 5%;
    left: 3%;
}

#login_layer_box {
    width: 500px;
    height: 600px;
    position: relative;
}

#login_layer {
    margin: 0 auto;
    width: 90%;
    height: 100%;
}

#login_title_box {
    width: 100%;
    height: 50px;
    text-align: center;
}

#login_title_box > img {
    height: 40px;
    vertical-align: bottom;
}

#login_title_box > span {
    color: white;
    font-size: 35px;
    line-height: 50px;
}

#login_input_box {
    height: 360px;
    width: 100%;
    margin-top: 20px;
    background: rgba(159, 159, 185, 0.5);
    overflow: hidden;
}

#prompt_box {
    position: absolute;
    width: 300px;
    height: 40px;
    left: 0;
    right: 0;
    top: 105px;
    margin: 0 auto;
    display: none;
}

#prompt_box_content {
    width: 100%;
    height: 30px;
    background: white;
    box-shadow: 2px 2px 5px #333;
    border-radius: 5px;
    text-align: center;
    line-height: 30px;
}

.test {
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

.inp_box {
    width: 90%;
    height: 50px;
    margin: 80px auto 0 auto;
    border: 1px solid gainsboro;
    border-radius: 10px;
    background: rgba(154, 152, 173, 0.3);
    display: flex;
    align-items: center;
}

.inp_box > img:nth-of-type(1) {
    height: 35px;
    padding: 0 15px;
    vertical-align: bottom;
}

.inp_box > img:nth-of-type(2),
.inp_box > img:nth-of-type(3) {
    height: 35px;
    //margin-left: 35px;
    vertical-align: bottom;
    cursor: pointer;
}

.inp_box > input {
    margin-top: 8px;
    height: 35px;
    width: 300px;
    font-size: 22px;
    background: none;
    border: none;
    outline: none;
}

.inp_box + .inp_box {
    margin-top: 20px;
}

.che_box {
    width: 90%;
    height: 20px;
    margin: 20px auto 0 auto;
    color: white;
}

.check_inp {
    vertical-align: middle;
    zoom: 150%;
}

.che_box > span {
    padding-left: 10px;
}

#btn_box {
    position: absolute;
    width: 100%;
    height: 80px;
    top: 340px;
    background: url(~@/assets/newLogin/dl.png) no-repeat center;
    background-size: 100% 100%;
    text-align: center;
    line-height: 60px;
    color: white;
    font-size: 26px;
}

#btn_box > span {
    cursor: pointer;
}

#login_bottom_bg {
    width: 100%;
    height: 170px;
    background: -webkit-gradient(
        linear,
        0 0,
        0 bottom,
        from(rgba(159, 159, 185, 0.3)),
        to(rgba(0, 0, 0, 0))
    );
    background: -moz-gradient(
        linear,
        0 0,
        0 bottom,
        from(rgba(159, 159, 185, 0.3)),
        to(rgba(0, 0, 0, 0))
    );
    background: -ms-linear-gradient(
        top,
        rgba(159, 159, 185, 0.3),
        rgba(0, 0, 0, 0)
    );
}
</style>
