<template>
    <el-dialog
        width="90%"
        title="配置试题视频"
        :visible.sync="show"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        append-to-body
        @close="close"
    >
        <el-row v-if="info.addOr !== 'view'" :gutter="20">
            <el-col :span="9">
                <el-card header="视频列表">
                    <el-row>
                        <vxe-toolbar>
                            <template v-slot:buttons>
                                <vxe-button @click="queryVideo"
                                    >刷新</vxe-button
                                >
                                <vxe-input
                                    v-model="table.search"
                                    type="search"
                                    placeholder="搜索"
                                    @input="searchInput"
                                />
                            </template>
                        </vxe-toolbar>
                        <vxe-table
                            ref="video"
                            border
                            resizable
                            :auto-resize="true"
                            :header-row-style="headerStyle"
                            :row-style="rowStyle"
                            align="center"
                            class="vxe-table-element"
                            height="400"
                            row-id="com_video_id"
                            :data="table.list"
                            :loading="table.loading"
                            :radio-config="{ highlight: true, trigger: 'row' }"
                            @radio-change="changeVideo"
                        >
                            <vxe-table-column
                                type="radio"
                                width="60"
                                fixed="left"
                            />
                            <vxe-table-column type="seq" width="60" />
                            <vxe-table-column
                                field="video_name"
                                title="视频标题"
                            />
                            <vxe-table-column
                                field="video_time"
                                title="视频时长"
                            >
                                <template v-slot="{ row }">
                                    <span>{{ getMyDate(row.video_time) }}</span>
                                </template>
                            </vxe-table-column>
                            <vxe-table-column
                                field="teacher_name"
                                title="授课老师"
                            />
                        </vxe-table>
                        <el-pagination
                            background
                            :current-page="table.currentPage"
                            :page-sizes="[5, 10, 15, 20, 50, 100]"
                            :page-size="table.pageSize"
                            layout="total, ->, sizes, prev, pager, next, jumper"
                            :total="table.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="10">
                <el-card header="试题列表">
                    <el-row>
                        <vxe-toolbar>
                            <template v-slot:buttons>
                                <vxe-button @click="querySubject"
                                    >刷新</vxe-button
                                >
                                <vxe-input
                                    v-model="table2.search"
                                    type="search"
                                    placeholder="搜索"
                                    @input="searchInput2"
                                />
                            </template>
                        </vxe-toolbar>
                        <vxe-table
                            ref="subject"
                            border
                            resizable
                            :auto-resize="true"
                            :header-row-style="headerStyle"
                            :row-style="rowStyle"
                            align="center"
                            class="vxe-table-element"
                            height="400"
                            row-id="com_subject_id"
                            :data="table2.list"
                            :loading="table2.loading"
                            :checkbox-config="{
                                highlight: true,
                                trigger: 'row',
                                reserve: true,
                                checkStrictly: true,
                            }"
                            @checkbox-change="changeSubject"
                            @checkbox-all="changeSubjectAll"
                        >
                            <vxe-table-column
                                type="checkbox"
                                width="60"
                                fixed="left"
                            />
                            <vxe-table-column type="seq" width="60" />
                            <vxe-table-column field="type" title="题型">
                                <template v-slot="{ row }">
                                    <span v-if="row.type === 1">单选题</span>
                                    <span v-else-if="row.type === 2"
                                        >多选题</span
                                    >
                                    <span v-else>判断题</span>
                                </template>
                            </vxe-table-column>
                            <vxe-table-column
                                field="content_text"
                                title="题干"
                            />
                        </vxe-table>
                        <el-pagination
                            small
                            background
                            :current-page="table2.currentPage"
                            :page-sizes="[5, 10, 15, 20, 50, 100]"
                            :page-size="table2.pageSize"
                            layout="total, ->, sizes, prev, pager, next, jumper"
                            :total="table2.total"
                            @size-change="handleSizeChange2"
                            @current-change="handleCurrentChange2"
                        />
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="5">
                <el-card header="配置分数">
                    <el-form ref="form" inline class="flex justify-between">
                        <el-form-item>
                            <span>单选题数量</span>
                            <el-input v-model="form.single_num" readonly />
                        </el-form-item>
                        <el-form-item>
                            <span>多选题数量</span>
                            <el-input v-model="form.multiple_num" readonly />
                        </el-form-item>
                        <el-form-item>
                            <span>判断题数量</span>
                            <el-input v-model="form.judge_num" readonly />
                        </el-form-item>
                    </el-form>
                    <el-form ref="form" inline class="flex justify-between">
                        <el-form-item>
                            <span>单选题分数</span>
                            <el-input
                                v-model="form.single_score"
                                @input="calcScore"
                            />
                        </el-form-item>
                        <el-form-item>
                            <span>多选题分数</span>
                            <el-input
                                v-model="form.multiple_score"
                                @input="calcScore"
                            />
                        </el-form-item>
                        <el-form-item>
                            <span>判断题分数</span>
                            <el-input
                                v-model="form.judge_score"
                                @input="calcScore"
                            />
                        </el-form-item>
                    </el-form>
                    <el-form ref="form" inline class="flex justify-between">
                        <el-form-item>
                            <span>总分数</span>
                            <el-input v-model="form.total_score" readonly />
                        </el-form-item>
                        <el-form-item>
                            <span>及格分数</span>
                            <el-input v-model="form.pass_score" />
                        </el-form-item>
                        <el-form-item>
                            <span>考试次数</span>
                            <el-input v-model="form.examination_time" />
                        </el-form-item>
                    </el-form>
                    <el-form ref="form" inline class="flex justify-between">
                        <el-form-item>
                            <span>考试时长</span>
                            <el-time-picker
                                v-model="form.time_limit"
                                class="formInput"
                                placeholder="任意时间点"
                                value-format="HH:mm:ss"
                                style="width: 150px"
                            />
                        </el-form-item>
                        <el-form-item>
                            <span>随机题目数</span>
                            <el-input
                                v-model="form.random"
                                @input="calcScore"
                            />
                        </el-form-item>
                    </el-form>
                    <div>
                        <el-button type="primary" size="small" @click="commit"
                            >添加</el-button
                        >
                        <el-button
                            type="info"
                            size="small"
                            @click="rollbackCourse"
                            >取消</el-button
                        >
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-card header="已选择列表">
                    <el-row>
                        <vxe-toolbar>
                            <template v-slot:buttons>
                                <vxe-button @click="CourseMsg">刷新</vxe-button>
                                <vxe-input
                                    v-model="table3.search"
                                    type="search"
                                    placeholder="搜索"
                                    @input="searchInput3"
                                />
                            </template>
                        </vxe-toolbar>
                        <vxe-table
                            ref="table"
                            border
                            resizable
                            :auto-resize="true"
                            :header-row-style="headerStyle"
                            :row-style="rowStyle"
                            align="center"
                            class="vxe-table-element"
                            height="400"
                            row-id="com_subject_id"
                            :expand-config="{
                                iconOpen: 'el-icon-minus',
                                iconClose: 'el-icon-plus',
                            }"
                            :data="table3.list"
                            :loading="table3.loading"
                        >
                            <vxe-table-column type="seq" width="60" />
                            <vxe-table-column type="expand" width="60">
                                <template v-slot:content="{ row }">
                                    <vxe-table
                                        ref="table"
                                        border
                                        resizable
                                        :auto-resize="true"
                                        :header-row-style="headerStyle"
                                        :row-style="rowStyle"
                                        align="center"
                                        class="vxe-table-element"
                                        height="400"
                                        :data="row.course_sub"
                                    >
                                        <vxe-table-column
                                            field="content_text"
                                            title="题干"
                                        />
                                        <vxe-table-column
                                            field="type"
                                            title="题型"
                                        >
                                            <template v-slot="{ row }">
                                                <span v-if="row.type === 1"
                                                    >单选题</span
                                                >
                                                <span v-else-if="row.type === 2"
                                                    >多选题</span
                                                >
                                                <span v-else>判断题</span>
                                            </template>
                                        </vxe-table-column>
                                    </vxe-table>
                                </template>
                            </vxe-table-column>
                            <vxe-table-column
                                field="video_name"
                                title="视频标题"
                            />
                            <vxe-table-column
                                field="video_time"
                                title="视频长度"
                            >
                                <template v-slot="{ row }">
                                    <span>{{ getMyDate(row.video_time) }}</span>
                                </template>
                            </vxe-table-column>
                            <vxe-table-column
                                field="teacher_name"
                                title="授课老师"
                            />
                            <vxe-table-column
                                field="single_score"
                                title="单选题分数"
                            />
                            <vxe-table-column
                                field="multiple_score"
                                title="多选题分数"
                            />
                            <vxe-table-column
                                field="judge_score"
                                title="判断题分数"
                            />
                            <vxe-table-column
                                field="total_score"
                                title="总分数"
                            />
                            <vxe-table-column
                                field="pass_score"
                                title="通过分数"
                            />
                            <vxe-table-column
                                field="examination_time"
                                title="补考次数"
                            />
                            <vxe-table-column
                                v-if="info.addOr !== 'view'"
                                field=""
                                title="操作"
                            >
                                <template v-slot="{ row, rowIndex }">
                                    <el-button
                                        type="danger"
                                        @click="remove(row)"
                                        >删除</el-button
                                    >
                                </template>
                            </vxe-table-column>
                        </vxe-table>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
import {
    queryVideo,
    querySubject,
    CourseMsg,
    RollBack,
    addCourseMsg,
    DetCoursemsg,
    determineTrainingHours,
} from "@/api/companyCourse";
// import {getCVideoForCourse} from '@/api/companyVideo'
// import {getSubjectForCourse} from '@/api/companySubject'
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { getMyDate, getTimeSecond, formatSeconds } from "@/utils/getDate";

export default {
    name: "NextCourse",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                course_id: this.info.course_id,
                video_id: 0,
                subject_id: "",
                single_score: 0,
                multiple_score: 0,
                judge_score: 0,
                single_num: 0,
                multiple_num: 0,
                judge_num: 0,
                total_score: 0,
                pass_score: 0,
                examination_time: 0,
                time_limit: "",
                random: 0,
                row: [],
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
                search: "",
                loading: false,
            },
            table2: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
                search: "",
                loading: false,
            },
            table3: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
                search: "",
                loading: false,
            },
            video: [],
            subject: [],
        };
    },
    created() {
        this.queryVideo();
        this.querySubject();
        this.CourseMsg();
    },
    methods: {
        rowStyle,
        headerStyle,
        getMyDate,
        changeVideo({ row }) {
            this.form.video_id = row.video_id;
        },
        changeSubject({ row, checked, rowIndex, $rowIndex }) {
            if (checked) {
                if (row.type === 1) {
                    this.form.single_num++;
                } else if (row.type === 2) {
                    this.form.multiple_num++;
                } else if (row.type === 3) {
                    this.form.judge_num++;
                }
            } else {
                if (row.type === 1) {
                    this.form.single_num--;
                } else if (row.type === 2) {
                    this.form.multiple_num--;
                } else if (row.type === 3) {
                    this.form.judge_num--;
                }
            }
        },
        changeSubjectAll({ records, reserves, checked }) {
            if (checked) {
                this.$message.error("暂未支持");
                return;
            }
            const data = records.concat(reserves);
            //console.log(data)
            // this.form.single_num=0
            // this.form.multiple_num=0
            // this.form.judge_num=0
            let single_num = 0;
            let multiple_num = 0;
            let judge_num = 0;

            for (const i of data) {
                if (checked) {
                    if (i.type === 1) {
                        // this.form.single_num++
                        single_num++;
                    } else if (i.type === 2) {
                        // this.form.multiple_num++
                        multiple_num++;
                    } else if (i.type === 3) {
                        // this.form.judge_num++
                        judge_num++;
                    }
                } else {
                    if (i.type === 1) {
                        this.form.single_num--;
                        // single_num--
                    } else if (i.type === 2) {
                        this.form.multiple_num--;
                        // multiple_num--
                    } else if (i.type === 3) {
                        this.form.judge_num--;
                        // judge_num--
                    }
                }
            }
            this.form.single_num = single_num;
            this.form.multiple_num = multiple_num;
            this.form.judge_num = judge_num;
            // if(!checked){
            //     this.form.single_num=0
            //     this.form.multiple_num=0
            //     this.form.judge_num=0
            // }
        },
        calcScore() {
            this.form.total_score =
                Number(this.form.single_score) * Number(this.form.random) +
                Number(this.form.multiple_score) * Number(this.form.random) +
                Number(this.form.judge_score) * Number(this.form.random);
        },
        searchInput() {
            this.queryVideo();
        },
        searchInput2() {
            this.querySubject();
        },
        searchInput3() {
            this.CourseMsg();
        },
        queryVideo() {
            this.table.loading = true;
            queryVideo({
                course_id: this.info.course_id,
                region_id: this.info.region_id,
                searchName: this.table.search,
                currentPage: this.table.currentPage,
                pageSize: this.table.pageSize,
            }).then((res) => {
                if (res.data.code === 1) {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                }
                this.table.loading = false;
            });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.queryVideo();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.queryVideo();
        },
        querySubject() {
            this.table2.loading = true;
            querySubject({
                course_id: this.info.course_id,
                region_id: this.info.region_id,
                searchName: this.table2.search,
                currentPage: this.table2.currentPage,
                pageSize: this.table2.pageSize,
            }).then((res) => {
                if (res.data.code === 1) {
                    this.table2.list = res.data.content;
                    this.table2.total = Number(res.data.totalElements);
                }
                this.table2.loading = false;
            });
        },
        handleSizeChange2(pageSize) {
            this.table2.pageSize = pageSize;
            this.table2.currentPage = 1;
            this.querySubject();
        },
        handleCurrentChange2(currentPage) {
            this.table2.currentPage = currentPage;
            this.querySubject();
        },
        CourseMsg() {
            this.table3.loading = true;
            CourseMsg({
                course_id: this.info.course_id,
                searchName: this.table3.search,
                currentPage: this.table3.currentPage,
                pageSize: this.table3.pageSize,
            }).then((res) => {
                if (res.data.code === 1) {
                    this.table3.list = res.data;
                }
                this.table3.loading = false;
            });
        },
        rollbackCourse() {
            RollBack(null, {
                course_id: this.info.course_id,
                img_path: this.info.video_img,
            }).then((res) => {
                if (res.data.code === 1) {
                    this.$message.success("已回滚课程！");
                    this.close();
                }
            });
        },
        remove(row) {
            DetCoursemsg(null, {
                course_msg_id: row.com_cmsg_id,
            }).then((res) => {
                if (res.data.code === 1) {
                    this.$message.success(res.data.msg);
                    this.queryVideo();
                    this.querySubject();
                    this.CourseMsg();
                }
            });
        },
        commit() {
            const total =
                Number(this.form.single_num) +
                Number(this.form.multiple_num) +
                Number(this.form.judge_num);
            const temp = this.$refs.subject.getCheckboxRecords();
            const temp1 = this.$refs.subject.getCheckboxReserveRecords();
            const arr = temp.concat(temp1);
            if (this.form.video_id === 0) {
                this.$message.error("请选择视频！");
                return;
            } else if (arr.length === 0) {
                this.$message.error("请选择试题！");
                return;
            } else if (this.$textNull(this.form.single_score)) {
                this.$message.error("请输入单选题分数！");
                return;
            } else if (this.$textNull(this.form.multiple_score)) {
                this.$message.error("请输入多选题分数！");
                return;
            } else if (this.$textNull(this.form.judge_score)) {
                this.$message.error("请输入判断题分数！");
                return;
            } else if (this.$textNull(this.form.total_score)) {
                this.$message.error("请输入总分数！");
                return;
            } else if (
                this.$textNull(this.form.pass_score) ||
                Number(this.form.pass_score) <= 0
            ) {
                this.$message.error("请输入及格分数！");
                return;
            } else if (this.$textNull(this.form.examination_time)) {
                this.$message.error("请输入补考次数！");
                return;
            } else if (Number(this.form.examination_time) <= 0) {
                this.$message.error("考试次数至少大于等于1");
                return;
            } else if (this.$textNull(this.form.time_limit)) {
                this.$message.error("请输入考试时间！");
                return;
            } else if (
                Number(this.form.pass_score) > Number(this.form.total_score)
            ) {
                this.$message.error("及格分数不允许大于总分数！");
                return;
            } else if (this.$textNull(this.form.random)) {
                this.$message.error("请输入随机题目数量！");
                return;
            } else if (Number(this.form.random) > total) {
                this.$message.error("随机题目数量不得大于总题目数！");
                return;
            }
            const sid = [];
            for (const i of arr) {
                sid.push(i.com_subject_id);
            }
            addCourseMsg(null, {
                com_course_id: this.info.course_id,
                com_video_id: this.form.video_id,
                single_score: this.form.single_score,
                multiple_score: this.form.multiple_score,
                judge_score: this.form.judge_score,
                total_score: this.form.total_score,
                pass_score: this.form.pass_score,
                examination_time: this.form.examination_time,
                subjectID: sid.join(),
                time_limit: this.form.time_limit,
                random: this.form.random,
            }).then((res) => {
                if (res.data.code === 1) {
                    this.$message.success(res.data.msg);
                    this.queryVideo();
                    this.querySubject();
                    this.CourseMsg();
                    this.form.single_score = 0;
                    this.form.single_num = 0;
                    this.form.multiple_score = 0;
                    this.form.multiple_num = 0;
                    this.form.judge_score = 0;
                    this.form.judge_num = 0;
                    this.form.pass_score = 0;
                    this.form.examination_time = 0;
                    this.form.random = 0;
                    this.form.time_limit = "";
                    this.form.total_score = 0;
                    this.$refs.subject.clearCheckboxRow();
                    this.$refs.subject.clearCheckboxReserve();
                }
            });
            let seconds = 0;
            for (const i of this.table3.list) {
                seconds += getTimeSecond(i.video_time);
            }
            const videoTemp = this.$refs.video.getRadioRecord();
            seconds += getTimeSecond(videoTemp.video_time);
            determineTrainingHours(null, {
                comCourseId: this.info.course_id,
                trainHours: formatSeconds(seconds),
            }).then((res) => {});
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped></style>
