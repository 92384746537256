<template>
  <el-dialog
    :visible.sync="show"
    width="75%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <!--    <div v-html="result.form"></div>-->
    <!--    <div id="pay"></div>-->
    <!-- 二维码容器 -->
    <div id="qrcode" class="erweima" style="width: 210px;margin:15px auto">
      <!--      <img :src="info.imgUrl" alt="">-->
    </div>
    <!--    <canvas id="canvas"></canvas>-->
  </el-dialog>
</template>

<script>
import { getPayForm } from '@/api/payment'
import QRCode from 'qrcodejs2'
export default {
name: 'Payment',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    list: {
      type: Array, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => []// 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      result: {
        form: ''
      }
    }
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.getPay()
    })
  },
  methods: {
    getPay() {
        const qrcode = new QRCode('qrcode', { // qrcode为容器ID
          width: 210, // 宽度
          height: 210, // 高度
          text: this.info.imgUrl // 二维码内容即 URL
        })
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
