<template>
  <el-dialog
    :visible.sync="show"
    width="78%"
    height="800px"
    :fullscreen="false"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <Purchase
      v-if="purchase.refresh"
      :dialog.sync="purchase.show"
      :row="purchase.row"
      :info="purchase.info"
      :list="purchase.list"
      @refresh="refreshDialog"
    />
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">课程表</span>
        </el-col>
      </el-row>
    </div>
    <div class="flex flex-wrap">
      <el-card v-for="(v,i) in table.list" :key="v.cultivate_type" style="height: 350px;width: 18%;margin: 10px">
        <img :src="$srcUrl.substr(0,$srcUrl.length-1)+v.img_path" class="image">
        <div>
          <span style="color: #5c8bff">【{{ v.title }}】</span>
          <p class="order-course" :title="v.content">{{ v.content }}</p>
        </div>
        <div class="flex justify-flex-end">
          <el-checkbox v-model="form.check[v.cultivate_type]" @change="(e)=>selectCourse(e,v, i)" />
        </div>
      </el-card>
    </div>
    <div class="mt-2 flex justify-flex-end">
      <el-button type="primary" size="small" @click="commit">立即购买</el-button>
    </div>
    <div style="height: 20px" />
    <el-pagination
      background
      :current-page="table.currentPage"
      :page-sizes="[5, 10, 15, 20, 50, 100]"
      :page-size="table.pageSize"
      layout="total, ->, sizes, prev, pager, next"
      :total="table.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
import { getCourseList } from '@/api/courseOrder'
import Purchase from './Purchase'

export default {
  name: 'Operate',
  components: {
    Purchase
  },
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {
      } // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      form: {
        course: [],
        check: []
      },
      purchase: {
        row: {},
        info: {},
        list: [],
        refresh: false, // 每次刷新DOM
        show: false
      },
      table: {
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        sortName: '',
        sortBy: ''
      }
    }
  },
  created() {
    this.getCourseList()
  },
  methods: {
    getCourseList() {
      getCourseList({
        currentPage: this.table.currentPage,
        pageSize: this.table.pageSize
      }).then(res => {
        if (res.data.code === 1) {
          this.table.list = res.data.content
          this.table.total = Number(res.data.totalElements)
        }
      })
    },
    selectCourse(e, v, i) {
      if (e) {
        this.form.course.push(v)
      } else {
        for (const i in this.form.course) {
          if (this.form.course[i].cultivate_type === v.cultivate_type) {
            this.form.course.splice(i, 1)
          }
        }
      }
    },
    commit() {
      if (this.form.course.length === 0) {
        this.$message.error('请选择至少一门课程！！！')
        return
      }
      this.purchase.refresh = true
      this.purchase.show = true
      this.purchase.list = this.form.course
    },
    handleSizeChange(pageSize) {
      this.table.pageSize = pageSize
      this.table.currentPage = 1
      this.getCourseList()
    },
    handleCurrentChange(currentPage) {
      this.table.currentPage = currentPage
      this.getCourseList()
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    },
    refreshDialog() {
      this.purchase.refresh = false
    }
  }
}
</script>

<style scoped>
.reply-div {
  min-height: 100px;
  border-bottom: 1px solid #3b3730;
}

.reply-title {
  font-size: 23px;
  color: #5A8CFF;
  font-weight: bolder;
}

.image {
  height: 140px;
  width: 100%;
}

::v-deep .el-checkbox__inner {
  border-radius: 50% !important;
  background-color: #F0F3FA;
  border: 2px solid #5c8bff;
  width: 18px;
  height: 18px;
}

::v-deep .el-checkbox__inner::after {
  height: 8px;
  left: 5px;
}

.order-course {
  height: 105px;
  overflow: auto;
  padding: 0;
}

/*滚动条的宽度*/
.order-course::-webkit-scrollbar {
  width: 10px;
  height: 50px;
  /*background-color: rgb(210, 220, 249);*/
}

/*滚动条的滑块*/
.order-course::-webkit-scrollbar-thumb {
  background-color: #F0F0F0;
  border-radius: 15px;
}
</style>
