<template>
  <div class="dashboard-container">
    <el-row type="flex" justify="space-between" :gutter="50">
      <el-col>
        <el-tabs v-model="tabs" type="border-card">
          <el-tab-pane name="1" label="统计查询">
            <TotalSearch
              @get-start="getStartT"
              @get-end="getEndT"
              @get-search="getSearchT"
            />
          </el-tab-pane>
          <el-tab-pane name="2" label="用户查询">
            <UserSearch
              @get-search="getSearchU"
            />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-card style="margin-top: 20px;">
      <div v-if="tabs==='1'">
        <Total :condition="totalCondition" />
      </div>
      <div v-if="tabs==='2'">
        <User :condition="userCondition" />
      </div>
    </el-card>
  </div>
</template>

<script>
  import Total from './Total'
  import TotalSearch from './Total/TotalSearch'
  import User from './User'
  import UserSearch from './User/UserSearch'
  export default {
    name: 'Index',
    components: {
      Total,
      TotalSearch,
      User,
      UserSearch
    },
    data() {
      return {
        tabs: '1',
        totalCondition: {
          start: '',
          end: '',
          search: ''
        },
        userCondition: {
          date: '',
          search: ''
        }
      }
    },
    created() {
    },
    methods: {
      getStartT(v) {
        this.totalCondition.start = v
      },
      getEndT(v) {
        this.totalCondition.end = v
      },
      getSearchT(v) {
        this.totalCondition.search = v
      },
      getSearchU(v) {
        this.userCondition.search = v
      }
    }
  }
</script>

<style scoped>

</style>
