import request from '@/utils/request'
const src = 'http://192.168.88.242:8888/config/pay1'
// 查询岗位列表
export function getPayForm(data) {
  return request({
    url: src,
    method: 'get',
    params: data
  })
}
