<template>
  <div class="dashboard-container">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="标准课程" name="标准课程">
        <el-row type="flex" justify="space-between" :gutter="50">
          <el-col>
            <span class="text-primary text-pad-right">分类</span>
            <el-cascader
              v-model="condition.category_id"
              :options="category"
              :props="{expandTrigger:'hover',value:'category_id',label:'name',leaf:'category_id',emitPath:false}"
              size="mini"
              class="input searchInput"
              :show-all-levels="false"
              filterable
              clearable
              @change="changeCondition"
            />
          </el-col>
          <el-col>
            <el-input
              v-model="condition.search1"
              class="input searchInput"
              size="mini"
              suffix-icon="el-icon-search"
              clearable
              placeholder="搜索"
              @input="changeCondition"
            />
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="企业培训" name="企业培训">
        <el-row type="flex" justify="space-between" :gutter="50">
          <el-col>
            <span class="text-primary text-pad-right">分类</span>
            <el-cascader
              v-model="condition.com_category_id"
              :options="categoryCom"
              :props="{expandTrigger:'hover',value:'com_category_id',label:'name',leaf:'com_category_id',emitPath:false}"
              size="mini"
              class="input searchInput"
              :show-all-levels="false"
              filterable
              clearable
              @change="changeCondition"
            />
          </el-col>
          <el-col>
            <el-input
              v-model="condition.search2"
              class="input searchInput"
              size="mini"
              suffix-icon="el-icon-search"
              clearable
              placeholder="搜索"
              @input="changeCondition"
            />
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <Standard v-if="activeName==='标准课程'" :condition="condition" />
    <Company v-else-if="activeName==='企业培训'" :condition="condition" />
  </div>
</template>

<script>
import Standard from './standardVideo'
import Company from './companyVideo'
import { getName } from '@/api/category'
import { transData } from '@/utils/handleJson'
import { getCategoryList } from '@/api/companyCategory'

export default {
  name: 'Index',
  components: {
    Standard, Company
  },
  data() {
    return {
      activeName: '标准课程',
      condition: {
        category_id: null,
        com_category_id: null,
        search1: '',
        search2: ''
      },
      category: [],
      categoryCom: []
    }
  },
  created() {
    this.getCategory()
    this.getCategoryCom()
  },
  methods: {
    changeCondition() {
      this.getList()
    },
    getCategory() {
      getName().then(res => {
        if (res.data.code === 1) {
          this.category = transData(res.data.content, 'category_id', 'p_id', 'children')
        }
      })
    },
    getCategoryCom() {
      getCategoryList().then(res => {
        if (res.data.code === 1) {
          this.categoryCom = transData(res.data.content, 'com_category_id', 'p_id', 'children')
        }
      })
    }

  }
}
</script>

<style scoped>

</style>
