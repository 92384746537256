<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="用户名称" :required="true">
            <el-input v-model="form.user_name" class="formInput" />
          </el-form-item>
          <el-form-item label="登录名称" :required="true">
            <el-input v-model="form.login_name" class="formInput" />
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="form.email" class="formInput" />
          </el-form-item>
          <el-form-item label="手机号" :required="true">
            <el-input v-model="form.telephone" class="formInput" maxlength="11" />
          </el-form-item>
          <el-form-item label="地区" :required="true" class="formInput">
            <el-select v-model="form.region" value-key="region_id" class="w-100" filterable clearable @change="changeRegion">
              <el-option v-for="(v,i) in region" :key="v.region_id" :value="v" :label="v.region_name" />
            </el-select>
          </el-form-item>
          <el-form-item label="管理公司" :required="true" class="formInput">
            <el-select v-model="form.company" value-key="company_id" class="w-100" filterable clearable>
              <el-option v-for="(v,i) in company" :key="v.company_id" :value="v" :label="v.company_name" />
            </el-select>
          </el-form-item>
          <el-form-item label="角色" :required="true" class="formInput">
            <el-select v-model="form.role_id" value-key="role_id" class="w-100" filterable="">
              <el-option v-for="(v,i) in role" :key="v.role_id" :value="v" :label="v.role_name" />
            </el-select>
          </el-form-item>
<!--          <el-form-item label="是否签名" :required="true" class="formInput">-->
<!--            <el-select v-model="form.isSign" class="w-100" filterable="">-->
<!--              <el-option :value="1" label="是" />-->
<!--              <el-option :value="0" label="否" />-->
<!--            </el-select>-->
<!--          </el-form-item>-->
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addMaster, selectLogin, updateMaster } from '@/api/master'
  import { getAllDistrict } from '@/api/region'
  import { getCompanyBySelect } from '@/api/company'
  import { textTel, textEmail } from '@/utils/validated'
  import { getAll as getAllRole } from '@/api/role'
  export default {
    name: 'Operate',
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        form: {
          user_name: '',
          login_name: '',
          email: '',
          telephone: '',
          region: '',
          company: '',
          role_id: '',
          role_g_id: 3,
          isSign: 0
        },
        region: [],
        company: [],
        role: [],
      }
    },
    created() {
      this.upd()
      this.getAllDistrict()
      this.getAllRole()
    },
    methods: {
      upd() {
        if (this.info.addOr === 'upd') {
          this.form.user_name = this.row.user_name
          this.form.login_name = this.row.login_name
          this.form.email = this.row.email
          this.form.telephone = this.row.telephone
          this.form.region = { region_id: this.row.region_id, region_name: this.row.region_name }
          this.getCompanyBySelect()
          this.form.company = { company_id: this.row.company_id, company_name: this.row.company_name }
          this.form.role_id = { role_id: this.row.role_id, role_name: this.row.role_name }
        }
      },
      getAllDistrict() {
        getAllDistrict({
          region_level: 3
        }).then(res => {
          if (res.data.code === 1) {
            this.region = res.data
          }
        })
      },
      getAllRole() {
        getAllRole({
          level: 4,
          role_g_id: this.form.role_g_id
        }).then(res => {
          if (res.data.code === 1) {
            this.role = res.data.content
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      },
      changeRegion() {
        this.getCompanyBySelect()
      },
      getCompanyBySelect() {
        getCompanyBySelect({
          region_id: this.form.region.region_id
        }).then(res => {
          if (res.data.code === 1) {
            this.company = res.data.content
          }
        })
      },
      // 提交添加
      commit() {
        if (this.$textNull(this.form.user_name)) {
          this.$message.error('请填写用户名称！')
          return
        } else if (this.$textNull(this.form.login_name)) {
          this.$message.error('请填写登录名称！')
          return
        } else if (this.$textNull(this.form.telephone)) {
          this.$message.error('请填写手机号！')
          return
        } else if (textTel(this.form.telephone)) {
          this.$message.error('请填写正确手机号格式！')
          return
        } else if (this.$textNull(this.form.region)) {
          this.$message.error('请选择地区！')
          return
        } else if (this.$textNull(this.form.company)) {
          this.$message.error('请选择公司！')
          return
        } else if (this.$textNull(this.form.role_id)) {
          this.$message.error('请选择角色！')
          return
        }
        if (!this.$textNull(this.form.email)) {
          if (textEmail(this.form.email)) {
            this.$message.error('请填写正确邮箱格式！')
            return
          }
        }
        if (this.info.addOr === 'add') {
          const result = new Promise((resolve) => {
            selectLogin({
              login_name: this.form.login_name
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.error('账号已被注册！')
              } else {
              }
              resolve(res)
            })
          })
          result.then(res => {
            if (res.data.code === 0) {
              addMaster(null, {
                user_name: this.form.user_name,
                login_name: this.form.login_name,
                email: this.form.email,
                telephone: this.form.telephone,
                region_id: this.form.region.region_id,
                region_name: this.form.region.region_name,
                company_id: this.form.company.company_id,
                company_name: this.form.company.company_name,
                role_id: this.form.role_id.role_id,
                role_g_id: 3,
                region_level: 3
              }).then(res => {
                if (res.data.code === 1) {
                  this.$message.success(res.data.msg)
                  this.close()
                  this.$emit('get-list')
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          })
        } else {
          if (this.form.login_name === this.row.login_name) {
            updateMaster(null, {
              master_id: this.row.master_id,
              user_name: this.form.user_name,
              login_name: this.form.login_name,
              email: this.form.email,
              telephone: this.form.telephone,
              region_id: this.form.region.region_id,
              region_name: this.form.region.region_name,
              company_id: this.form.company.company_id,
              company_name: this.form.company.company_name,
              role_id: this.form.role_id.role_id,
              role_g_id: 3,
              region_level: 3
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.success(res.data.msg)
                this.close()
                this.$emit('get-list')
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            const result = new Promise((resolve) => {
              selectLogin({
                login_name: this.form.login_name
              }).then(res => {
                if (res.data.code === 1) {
                  this.$message.error('账号已被注册！')
                } else {
                }
                resolve(res)
              })
            })
            result.then(res => {
              if (res.data.code === 0) {
                updateMaster(null, {
                  master_id: this.row.master_id,
                  user_name: this.form.user_name,
                  login_name: this.form.login_name,
                  email: this.form.email,
                  telephone: this.form.telephone,
                  region_id: this.form.region.region_id,
                  region_name: this.form.region.region_name,
                  company_id: this.form.company.company_id,
                  company_name: this.form.company.company_name,
                  role_id: this.form.role_id.role_id,
                  role_g_id: 3,
                  region_level: 3
                }).then(res => {
                  if (res.data.code === 1) {
                    this.$message.success(res.data.msg)
                    this.close()
                    this.$emit('get-list')
                  } else {
                    this.$message.error(res.data.msg)
                  }
                })
              }
            })
          }
        }
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
