<template>
    <div>
        <ViewError
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @refresh="refreshDialog"
        />
        <el-row :gutter="50" class="mt-2">
            <!--      <el-col :span="8">-->
            <!--        <ZyfInfo-->
            <!--          class="zyf-info"-->
            <!--          name="企业人员"-->
            <!--          :img-url="require('@/assets/main/管理人员.png')"-->
            <!--          :count="baseInfo.person"-->
            <!--          unit="人"-->
            <!--          icon="el-icon-user"-->
            <!--          @click.native="toPerson"-->
            <!--        />-->
            <!--      </el-col>-->
            <el-col :span="8">
                <ZyfInfo
                    class="zyf-info zyf-info-small"
                    :name="
                        condition.area.level == 3
                            ? '企业数量'
                            : '下级行政区域数量'
                    "
                    :img-url="
                        require(condition.area.level == 3
                            ? '@/assets/main/企业数量1.png'
                            : '@/assets/main/区域.png')
                    "
                    :img-arrow-url="require('@/assets/main/蓝箭头.png')"
                    :count="baseInfo.car"
                    unit="人"
                    icon="el-icon-milk-tea"
                />
            </el-col>
            <el-col :span="8">
                <ZyfInfo
                    class="zyf-info zyf-info-small"
                    :name="condition.area.level == 3 ? '学习人数' : '企业数量'"
                    :img-url="
                        require(condition.area.level == 3
                            ? '@/assets/main/学习人员.png'
                            : '@/assets/main/企业数量.png')
                    "
                    :img-arrow-url="require('@/assets/main/紫箭头.png')"
                    :count="baseInfo.student"
                    unit="人"
                    icon="el-icon-dessert"
                />
            </el-col>
            <el-col :span="8">
                <ZyfInfo
                    class="zyf-info zyf-info-small"
                    :img-url="require('@/assets/main/添加.png')"
                />
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mt-2">
            <el-col :span="12">
                <el-card style="height: 290px" class="zyf-info">
                    <ZyfTitle title="学习统计"></ZyfTitle>
                    <div class="flex justify-around">
                        <div class="text-center">
                            <CirclePass
                                id="d1"
                                name=""
                                :color="['#41B273', '#f0f0f0']"
                                :pop-data="count.rate"
                                style="flex: 1; width: 200px; height: 190px"
                            />
                            <div>
                                {{
                                    condition.area.level == 3
                                        ? "企业完成率"
                                        : "区域完成率"
                                }}
                            </div>
                        </div>
                        <div class="text-center">
                            <CirclePass
                                id="d2"
                                name=""
                                :color="['#E47372', '#f0f0f0']"
                                :pop-data="errRate.toFixed(2)"
                                style="flex: 1; width: 200px; height: 190px"
                            />
                            <div>
                                {{
                                    condition.area.level == 3
                                        ? "学员完成率"
                                        : "企业完成率"
                                }}
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="zyf-info">
                    <div class="flex flex-column" style="height: 250px">
                        <ZyfTitle title="达标走势"></ZyfTitle>
                        <div class="flex-1">
                            <LineBar
                                class="w-100 h-100"
                                :line-data="lineData"
                            />
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="mt-2">
            <el-col :span="12">
                <el-card style="height: 290px" class="zyf-info">
                    <div class="flex justify-between">
                        <ZyfTitle title="企业信息"></ZyfTitle>
                        <div>
                            <el-input
                                v-model="filterName"
                                class="input searchInput"
                                size="mini"
                                suffix-icon="el-icon-search"
                                placeholder="搜索"
                            />
                        </div>
                    </div>
                    <div class="mt-1">
                        <vxe-table
                            ref="table"
                            border
                            resizable
                            :auto-resize="true"
                            :sync-resize="true"
                            :header-row-style="headerStyle"
                            :row-style="rowStyle"
                            align="center"
                            class="vxe-table-element"
                            height="220"
                            :data="tableList"
                        >
                            <template v-if="condition.area.level == 3">
                                <vxe-table-column
                                    field="user_name"
                                    title="企业名称"
                                />
                                <vxe-table-column title="学员总数" />
                                <vxe-table-column title="达标人数" />
                                <vxe-table-column title="未达标人数" />
                                <vxe-table-column
                                    field="watch_rate"
                                    title="完成率"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            >{{
                                                (row.watch_rate * 100).toFixed(
                                                    2
                                                )
                                            }}%</span
                                        >
                                    </template>
                                </vxe-table-column>
                            </template>
                            <template v-else>
                                <vxe-table-column
                                    field="user_name"
                                    title="区域名称"
                                />
                                <vxe-table-column title="企业总数" />
                                <vxe-table-column title="达标企业" />
                                <vxe-table-column title="未达标企业" />
                                <vxe-table-column
                                    field="watch_rate"
                                    title="完成率"
                                >
                                    <template v-slot="{ row }">
                                        <span
                                            >{{
                                                (row.watch_rate * 100).toFixed(
                                                    2
                                                )
                                            }}%</span
                                        >
                                    </template>
                                </vxe-table-column>
                            </template>
                        </vxe-table>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card>
                    <div class="zyf-info" style="height: 250px">
                        <ZyfTitle title="通知公告"></ZyfTitle>
                        <div class="flex-1 flex flex-column justify-between">
                            <div
                                v-for="v in noticeList"
                                :key="v.notice_id"
                                class="flex justify-between zyf-notice mt-1"
                            >
                                <div class="flex justify-between">
                                    <div
                                        class="ellipsis-1"
                                        style="width: 230px"
                                        :title="v.title"
                                    >
                                        {{ v.title }}
                                    </div>
                                </div>
                                <div
                                    class="flex flex-column align-center"
                                    style="font-size: 14px"
                                >
                                    <div class="flex justify-flex-end">
                                        {{ v.is_read }}人
                                    </div>
                                    <div>已读人数</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card class="zyf-info">
                    <div class="flex flex-column" style="height: 250px">
                        <div class="flex align-center">
                            <img
                                :src="require('@/assets/main/手机 (2).png')"
                                alt=""
                                style="width: 33px; height: 33px"
                            />
                            <span class="ml-2" style="font-weight: bold"
                                >下载中心</span
                            >
                        </div>
                        <div class="flex-1">
                            <el-row
                                :gutter="10"
                                class="flex align-center h-100"
                            >
                                <el-col :span="12">
                                    <div
                                        class="zyf-down flex-1 flex justify-center align-center"
                                    >
                                        <img
                                            :src="
                                                require('@/assets/main/安卓 B.png')
                                            "
                                            alt=""
                                        />
                                        <el-popover
                                            placement="right"
                                            width="200"
                                            trigger="hover"
                                        >
                                            <div
                                                id="android"
                                                class="flex justify-center"
                                            />
                                            <span
                                                slot="reference"
                                                style="
                                                    font-size: 20px;
                                                    font-weight: bold;
                                                "
                                                >Android</span
                                            >
                                        </el-popover>
                                    </div>
                                    <div
                                        class="zyf-down flex-1 flex justify-center align-center mt-2"
                                    >
                                        <!--                    <el-popover-->
                                        <!--                      placement="right"-->
                                        <!--                      width="200"-->
                                        <!--                      trigger="hover">-->
                                        <!--&lt;!&ndash;                      <div id="android" class="erweima"></div>&ndash;&gt;-->
                                        <!--&lt;!&ndash;                      <el-button slot="reference"></el-button>&ndash;&gt;-->
                                        <!--                      <i slot="reference" class="el-icon-menu"></i>-->
                                        <!--                    </el-popover>-->
                                        <span />
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div
                                        class="zyf-down flex-1 flex justify-center align-center"
                                    >
                                        <img
                                            :src="
                                                require('@/assets/main/苹果.png')
                                            "
                                            alt=""
                                        />
                                        <el-popover
                                            placement="right"
                                            width="200"
                                            trigger="hover"
                                        >
                                            <div
                                                id="IOS"
                                                class="flex justify-center"
                                            />
                                            <span
                                                slot="reference"
                                                style="
                                                    font-size: 20px;
                                                    font-weight: bold;
                                                "
                                                >App Store</span
                                            >
                                        </el-popover>
                                        <!--                    <span style="font-size: 20px;font-weight: bold">App Store</span>-->
                                    </div>
                                    <div
                                        class="zyf-down flex-1 flex justify-center align-center mt-2"
                                    >
                                        <a
                                            href="http://192.168.88.158:9887/files/悟空护驾APP用户手册.doc"
                                            target="_blank"
                                            >使用手册</a
                                        >
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import ZyfInfo from "@/views/components/ZyfInfo";
import CirclePass from "@/components/Echarts/CirclePass";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import XEUtils from "xe-utils";
import LineBar from "@/components/Echarts/LineBar";
import {
    companySet,
    getCompanyAllCarNum,
    getCompanyCourseStaticstice,
    getNotPassUserMsg,
    proportionOfWrongQuestions,
} from "@/api/statistics";
import { getRelease } from "@/api/notice";
import ViewError from "@/views/supervise/CompanyEnd/ViewError";
import QRCode from "qrcodejs2";
import ZyfTitle from "@/views/components/ZyfTitle";
export default {
    name: "Index",
    components: {
        ViewError,
        ZyfInfo,
        CirclePass,
        LineBar,
        ZyfTitle,
    },
    props: {
        condition: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: true,
            count: {
                rate: 0,
            },
            errRate: 0,
            filterName: "",
            table: {
                list: [],
            },
            lineData: {
                value: [],
                xData: [],
            },
            baseInfo: {
                person: 0,
                car: 0,
                student: 0,
            },
            noticeList: [],
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
        };
    },
    computed: {
        tableList(v) {
            const filterName = XEUtils.toString(this.filterName)
                .trim()
                .toLowerCase();
            if (filterName) {
                const filterRE = new RegExp(filterName, "gi");
                const searchProps = ["user_name"];
                const rest = this.table.list.filter((item) =>
                    searchProps.some(
                        (key) =>
                            XEUtils.toString(item[key])
                                .toLowerCase()
                                .indexOf(filterName) > -1
                    )
                );
                return rest.map((row) => {
                    const item = Object.assign({}, row);
                    searchProps.forEach((key) => {
                        item[key] = XEUtils.toString(item[key]).replace(
                            filterRE,
                            (match) => `${match}`
                        );
                    });
                    return item;
                });
            }
            return this.table.list;
        },
    },
    watch: {
        condition: {
            handler(v) {
                this.changeCondition();
            },
            deep: true,
        },
    },
    created() {
        // this.getLine()
        this.noticeList.push({ title: "关于成长" }, { title: "关于成长" });
    },
    mounted() {
        this.$nextTick(() => {
            // this.getQRCode()
        });
    },
    methods: {
        rowStyle,
        headerStyle,
        changeCondition() {
            // this.getLine()
            // // this.companySet()
            // this.getCompanyCourseStaticstice()
            // this.getCompanyAllCarNum()
            // this.getNotPassUserMsg()
            // this.proportionOfWrongQuestions()
            // this.getRelease()
        },
        getLine() {
            // this.lineData={
            //   xData : ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            //   value : [92, 29, 93, 80, 40, 50, 16, 72, 83, 19, 48, 59]
            // }
        },
        // openError() {
        //   // this.dialog.show = true
        //   // this.dialog.refresh = true
        //   this.$router.push({ path: '/supervise/CompanyEnd/ViewError', query: { condition: this.condition }})
        // },
        companySet() {
            const loading = this.$loading({
                lock: true,
                text: "查询中",
                background: "rgba(0, 0, 0, 0.7)",
            });
            companySet({
                company_id: this.$loginInfo.company_id,
                time: this.condition.date,
            })
                .then((res) => {
                    if (res.data.code === 1) {
                        const arr = Number(res.data.totalElements);
                        this.baseInfo.person = arr.allUser;
                        this.table.list = res.data.value;
                        this.count.rate = (arr.userFinishing * 100).toFixed(2);
                    }
                    loading.close();
                })
                .catch((e) => {
                    loading.close();
                });
        },
        getCompanyAllCarNum() {
            this.loading = true;
            getCompanyAllCarNum({
                companyId: this.$loginInfo.company_id,
            })
                .then((res) => {
                    if (res.data.code === 1) {
                        this.baseInfo.car = res.data;
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        getCompanyCourseStaticstice() {
            const date = this.condition.date.split("-");
            getCompanyCourseStaticstice({
                companyId: this.$loginInfo.company_id,
                year: date[0],
            }).then((res) => {
                if (res.data.code === 1) {
                    const arr = res.data;
                    this.lineData.value = [];
                    for (const i of arr) {
                        const rate = i.company_pass_num / i.amount_user;
                        const result = rate
                            ? Number((rate * 100).toFixed(2))
                            : 0;
                        this.lineData.value.push(result);
                    }
                    this.lineData.xData = [
                        "1月",
                        "2月",
                        "3月",
                        "4月",
                        "5月",
                        "6月",
                        "7月",
                        "8月",
                        "9月",
                        "10月",
                        "11月",
                        "12月",
                    ];
                }
            });
        },
        getNotPassUserMsg() {
            const date = this.condition.date.split("-");
            getNotPassUserMsg({
                companyId: this.$loginInfo.company_id,
                year: date[0],
                month: date[1],
            }).then((res) => {
                if (res.data.code === 1) {
                    const arr = Number(res.data.totalElements);
                    this.baseInfo.person = arr.allUser;
                    this.baseInfo.student = arr.isUser;
                    this.table.list = res.data.value;
                    this.count.rate = (arr.userFinishing * 100).toFixed(2);
                }
            });
        },
        proportionOfWrongQuestions() {
            const date = this.condition.date.split("-");
            proportionOfWrongQuestions({
                companyId: this.$loginInfo.company_id,
                year: date[0],
                month: date[1],
            }).then((res) => {
                if (res.data.code === 1) {
                    this.errRate = res.data.errRate;
                }
            });
        },
        getRelease() {
            getRelease({
                company_id: this.$loginInfo.company_id,
                sTime: this.condition.date + "-" + "01 00:00:00",
                eTime: this.condition.date + "-" + "31 23:59:59",
            }).then((res) => {
                if (res.data.code === 1) {
                    this.noticeList = res.data.content;
                }
            });
        },
        getQRCode() {
            new QRCode("android", {
                // qrcode为容器ID
                width: 150, // 宽度
                height: 150, // 高度
                text: "https://zdwy.online:5159/files/download/wkhj.1.5.0.apk", // 二维码内容即 URL
            });
            new QRCode("IOS", {
                // qrcode为容器ID
                width: 150, // 宽度
                height: 150, // 高度
                text:
                    "https://apps.apple.com/cn/app/%E6%82%9F%E7%A9%BA%E6%8A%A4%E9%A9%BE/id1404212799", // 二维码内容即 URL
            });
        },
        toNotice() {
            this.$router.push("/businessCnf/notice");
        },
        // toPerson() {
        //   this.$router.push('/manageCenter/person')
        // },
        // toCarManage() {
        //   this.$router.push('/manageCenter/manageCar')
        // },
        // toCompanySearch() {
        //   this.$router.push('/statistics/safeTrain')
        // },
        refreshDialog() {
            this.dialog.refresh = false;
        },
    },
};
</script>

<style scoped>
.zyf-info {
    padding: 0 40px;
}
.zyf-info-small {
    height: 145px;
    border-radius: 20px;
}
.zyf-notice {
    font-size: 20px;
    font-weight: bold;
    height: 50px;
    border-bottom: 1px solid #afd0ff;
    cursor: pointer;
}

.zyf-down {
    /*width: 200px;*/
    border-radius: 10px;
    height: 60px;
    border: 1px solid #979ca8;
}
</style>
