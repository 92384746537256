<template>
  <div class="dashboard-container">
    <Operate
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <SetUser
      v-if="dialog.refresh2"
      :dialog.sync="dialog.show2"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <el-card>
      <el-row type="flex" justify="space-between" :gutter="50">
        <el-col>
          <span class="text-primary text-pad-right">发布状态</span>
          <el-select v-model="condition.status" value-key="region_id" size="mini" class="input searchInput" @change="changeCondition">
            <el-option :value="2" label="全部" />
            <el-option :value="1" label="已发布" />
            <el-option :value="0" label="未发布" />
          </el-select>
        </el-col>
        <el-col>
          <span class="text-primary text-pad-right">企业</span>
          <el-select v-model="condition.company_id" filterable clearable size="mini" class="input searchInput" @change="changeCondition">
            <el-option v-for="(v,i) in companies" :key="v.company_id" :value="v.company_id" :label="v.company_name" />
          </el-select>
        </el-col>
        <el-col>
          <el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search" placeholder="搜索" @input="searchInput" />
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <el-row type="flex" justify="between">
              <el-col>
                <el-button type="primary" size="mini" @click="openDialog({title:'添加课程',addOr:'add'})">添加课程</el-button>
                <el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
              </el-col>
              <el-col :span="1">
                <vxe-button @click="getList">刷新</vxe-button>
              </el-col>
            </el-row>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="job_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="checkbox" width="60" fixed="left" />
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            field="title"
            title="课程名称"
          />
          <vxe-table-column
            field=""
            title="学习有效期"
          >
            <template v-slot="{ row, rowIndex }">
              <span>{{ row.entry_time }}-{{ row.failure_time }}</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            title="课程封面"
          >
            <template v-slot="{ row, rowIndex }">
              <img :src="$srcUrl.substr(0,$srcUrl.length-1)+row.img_path" alt="" style="width: 100px">
            </template>
          </vxe-table-column>
          <!--          <vxe-table-column-->
          <!--            field="cultivate_type"-->
          <!--            title="培训类型"-->
          <!--          >-->
          <!--            <template v-slot="{ row, rowIndex }">-->
          <!--              <span v-if="row.cultivate_type===1">岗前培训</span>-->
          <!--              <span v-else-if="row.cultivate_type===2" class="text-success">安全培训</span>-->
          <!--              <span v-else-if="row.cultivate_type===3" class="text-success">继续教育</span>-->
          <!--            </template>-->
          <!--          </vxe-table-column>-->
          <!--          <vxe-table-column-->
          <!--            field=""-->
          <!--            title="培训岗位"-->
          <!--          />-->
          <vxe-table-column
            field="train_hours"
            title="培训时长"
          >
            <template v-slot="{row}">
              <span>{{ getTrain(row.train_hours) }}</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="company_name"
            title="所属企业"
          />
          <!--          <vxe-table-column-->
          <!--            field="course_type"-->
          <!--            title="课程类型"-->
          <!--          >-->
          <!--            <template v-slot="{ row, rowIndex }">-->
          <!--              <span v-if="row.cultivate_type===1">安全培训</span>-->
          <!--              <span v-else-if="row.cultivate_type===2" class="text-success">临时学习</span>-->
          <!--              <span v-else-if="row.cultivate_type===3" class="text-success">违规学习</span>-->
          <!--            </template>-->
          <!--          </vxe-table-column>-->
          <vxe-table-column
            field="is_release"
            title="发布状态"
          >
            <template v-slot="{ row, rowIndex }">
              <span v-if="row.is_release===1" class="text-success">已发布</span>
              <span v-else class="text-danger">未发布</span>
            </template>
          </vxe-table-column>
          <vxe-table-column field="" title="操作">
            <template v-slot="{ row, rowIndex }">
              <el-dropdown size="small" type="primary">
                <el-button size="small" type="primary">
                  操作<i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <div v-if="row.is_release===0">
                    <el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改课程',addOr:'upd'},row)">修改</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-upload2" @click.native="release(row)">发布</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
                  </div>
                  <div v-else>
                    <el-dropdown-item icon="el-icon-s-custom" @click.native="setUser({title:'设置人员',addOr:'upd'},row)">设置人员</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">下架</el-dropdown-item>
                  </div>
                  <el-dropdown-item icon="el-icon-zoom-in" @click.native="openDialog({title:'查看课程',addOr:'view'},row)">查看课程</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </vxe-table-column>
        </vxe-table>
        <div style="height: 20px" />
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
  import { AllCourse, DetCourse, releaseCourse } from '@/api/companyCourse'
  import { getAllCompanies } from '@/api/company'
  import { secondsToChinese, getFormatTimeSecond } from '@/utils/getDate'
  import { getAllRegion, getAllDistrict } from '@/api/region'
  import Operate from './Operate'
  import SetUser from './SetUser'
  export default {
    name: 'Index',
    components: {
      Operate,
      SetUser
    },
    data() {
      return {
        loading: false,
        condition: {
          region_id: null,
          company_id: '',
          search: '',
          status: 2,
          course_type: 0,
          train_encoder: '',
          cultivate_type: 0
        },
        table: {
          list: [],
          total: 0,
          currentPage: 1,
          pageSize: 10,
          sortName: '',
          sortBy: ''
        },
        dialog: {
          row: Object,
          info: {},
          refresh: false, // 每次刷新DOM
          refresh2: false, // 每次刷新DOM
          show: false,
          show2: false
        },
        region: [],
        companies: []
      }
    },
    created() {
      this.getList()
      this.getAllCompanies()
    },
    methods: {
      rowStyle,
      headerStyle,
      // 打开dialog
      openDialog(info, row) {
        this.dialog.refresh = true
        this.dialog.show = true
        this.dialog.info = info
        this.dialog.row = row
      },
      // 打开dialog
      setUser(info, row) {
        this.dialog.refresh2 = true
        this.dialog.show2 = true
        this.dialog.info = info
        this.dialog.row = row
      },
      getAllCompanies() {
        getAllCompanies().then(res => {
          if (res.data.code === 1) {
            this.companies = res.data.content
          }
        })
      },
      searchInput(v) {
        this.table.currentPage = 1
        this.getList()
      },
      changeCondition() {
        this.table.currentPage = 1
        this.getList()
      },
      getTrain(v) {
        if (v != undefined || v) {
          return secondsToChinese(getFormatTimeSecond(v))
        }
        return ''
      },
      release(row) {
        const a = ac => {
          if (ac === 'confirm') {
            releaseCourse(null, {
              course_id: row.com_course_id,
              // region_id: row.region_id,
              company_id: row.company_id
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.success(res.data.msg)
                this.getList()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.info('取消了发布！！！')
          }
        }
        this.$topicMsg('确认发布吗！！！', a)
      },
      getList() {
        this.loading = true
        AllCourse({
          role_id: this.$loginInfo.role_id,
          status: this.condition.status,
          course_type: this.condition.course_type,
          cultivate_type: this.condition.cultivate_type,
          company_id: this.condition.company_id,
          searchName: this.condition.search,
          currentPage: this.table.currentPage,
          pageSize: this.table.pageSize
        }).then(res => {
          if (res.data.code === 1) {
            this.table.list = res.data.content
            this.table.total = Number(res.data.totalElements)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      },
      remove(info, row) {
        const id = []
        const img = []
        if (info.del === 'batch') {
          const arr = this.$refs.table.getCheckboxRecords()
          const arr1 = this.$refs.table.getCheckboxReserveRecords()
          const batchDel = arr.concat(arr1)
          for (const i of batchDel) {
            id.push(i.com_course_id)
            img.push(i.img_path)
          }
          if (id.length === 0) {
            this.$message.error('请勾选！！！')
            return
          }
        } else {
          id.push(row.com_course_id)
          img.push(row.img_path)
        }
        const a = ac => {
          if (ac === 'confirm') {
            DetCourse(null, {
              course_id: id.join(),
              img_path: img.join()
            }).then(res => {
              if (res.data.code === 1) {
                this.$message.success(res.data.msg)
                this.$refs.table.clearCheckboxRow()
                this.$refs.table.clearCheckboxReserve()
                this.getList()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.info('取消了删除！！！')
          }
        }
        this.$topicMsg('确认删除吗！！！', a)
      },
      handleSizeChange(pageSize) {
        this.table.pageSize = pageSize
        this.table.currentPage = 1
        this.getList()
      },
      handleCurrentChange(currentPage) {
        this.table.currentPage = currentPage
        this.getList()
      },
      refreshDialog() {
        this.dialog.refresh = false
        this.dialog.refresh2 = false
      }
    }
  }
</script>

<style scoped>

</style>
