import request from '@/utils/request'
const src = 'com/Curriculum'
// 添加课程根据地区获取题目
export function AllCourse(param) {
  return request({
    url: src + '/AllCourse',
    method: 'get',
    params: param
  })
}

// 下一步按钮创建课程
export function addCourse(data, param) {
  return request({
    url: src + '/addCourse',
    method: 'POST',
    data,
    params: param
  })
}

// 确定按钮添加课程信息
export function addCourseMsg(data, param) {
  return request({
    url: src + '/addCourseMsg',
    method: 'POST',
    data,
    params: param
  })
}

// 培训时长
export function determineTrainingHours(data, param) {
  return request({
    url: src + '/determineTrainingHours',
    method: 'put',
    data,
    params: param
  })
}

// 发布课程
export function releaseCourse(data, param) {
  return request({
    url: src + '/releaseCourse',
    method: 'PUT',
    data,
    params: param
  })
}

// 课程添加视频查询
export function queryVideo(param) {
  return request({
    url: src + '/queryVideo',
    method: 'GET',
    params: param
  })
}

// 课程添加试题查询
export function querySubject(param) {
  return request({
    url: src + '/querySubject',
    method: 'GET',
    params: param
  })
}

// 课程已添加所有视频查询
export function CourseMsg(param) {
  return request({
    url: src + '/CourseMsg',
    method: 'GET',
    params: param
  })
}

// 添加试题回滚动作
export function RollBack(data, param) {
  return request({
    url: src + '/RollBack',
    method: 'DELETE',
    data,
    params: param
  })
}

// 下架删除操作
export function DetCourse(data, param) {
  return request({
    url: src + '/DetCourse',
    method: 'DELETE',
    data,
    params: param
  })
}

// 更新课程操作
export function UpdCourse(data, param) {
  return request({
    url: src + '/UpdCourse',
    method: 'PUT',
    data,
    params: param
  })
}

// 删除单个试题中单个视频与配题
export function DetCoursemsg(data, param) {
  return request({
    url: src + '/DetCoursemsg',
    method: 'DELETE',
    data,
    params: param
  })
}

// 修改配课人员权限--更新权限
export function UpdUserCourse(data, param) {
  return request({
    url: src + '/UpdUserCourse',
    method: 'PUT',
    data,
    params: param
  })
}

// 查询公司当月课程
export function setCompanyHasCourse(param) {
  return request({
    url: src + '/setCompanyHasCourse',
    method: 'GET',
    params: param
  })
}

// 根据公司地区查出本地区的课程
export function getCourseByCompanyRegion(param) {
  return request({
    url: src + '/getCourseByCompanyRegion',
    method: 'GET',
    params: param
  })
}

// 根据课程详情ID查询视频考题分数
export function getVideoExamByMsgId(param) {
  return request({
    url: src + '/getVideoExamByMsgId',
    method: 'GET',
    params: param
  })
}
