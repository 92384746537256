<template>
    <el-dialog
        :visible.sync="show"
        width="45%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <Payment
            v-if="payment.refresh"
            :dialog.sync="payment.show"
            :info="payment.info"
            @refresh="refreshDialog"
        />
        <!--    <div slot="title" class="header-title">-->
        <!--      <el-row :gutter="5">-->
        <!--        <el-col :span="16">-->
        <!--          <span class="title-name">结算</span>-->
        <!--        </el-col>-->
        <!--      </el-row>-->
        <!--    </div>-->
        <div
            v-for="(v, i) in list"
            :key="v.course_id"
            class="flex flex-column justify-center"
            style="height: 200px; border-bottom: 1px solid #f0f0f0"
        >
            <div style="font-size: 20px; font-weight: bolder">
                <span>类型名称：</span>
                <span>{{ v.title }}</span>
            </div>
            <div class="mt-1">
                <span class="text-width"
                    ><span class="text-danger">*</span><span>数量</span></span
                >
                <el-input-number
                    v-model="form.num[i]"
                    :min="1"
                    :max="200"
                    size="mini"
                    label="描述文字"
                />
                <span>课时</span>
            </div>
            <div class="mt-1">
                <span class="text-width"
                    ><span class="text-danger">*</span
                    ><span>生效时间</span></span
                >
                <el-date-picker
                    v-model="form.time[i]"
                    type="month"
                    value-format="yyyy-MM-dd"
                    size="mini"
                    placeholder="选择日期"
                />
            </div>
            <div class="mt-1">
                <span class="text-width"
                    ><span class="text-danger">*</span><span>合计</span></span
                >
                <span
                    style="color: #5c8bff; font-weight: bolder; font-size: 18px"
                    >￥{{ form.num[i] * form.price || 0 }}</span
                >
            </div>
        </div>
        <div class="flex mt-2">
            <span class="text-width-big">
                <span class="text-danger">*</span
                ><span style="font-size: 20px; font-weight: bolder"
                    >支付方式：</span
                >
            </span>
            <div style="font-size: 20px; font-weight: bolder">
                <el-radio-group v-model="form.pay">
                    <el-radio :label="1">支付宝</el-radio>
                    <el-radio :label="2">微信支付</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div>
            <span class="text-width-big"
                ><span style="font-size: 20px; font-weight: bolder"
                    >实付款：</span
                ></span
            >
            <span style="color: #5c8bff; font-weight: bolder; font-size: 18px"
                >￥{{ totalPrice }}</span
            >
        </div>
        <div class="mt-2 flex justify-flex-end">
            <el-button type="info" size="small" @click="close">取消</el-button>
            <el-button type="primary" size="small" @click="commit"
                >立即购买</el-button
            >
        </div>
        <div id="pay" />
    </el-dialog>
</template>

<script>
import { addOrder, addOrderWX } from "@/api/courseOrder";
import Payment from "./Payment";
import { getPayForm } from "@/api/payment";
export default {
    name: "Purchase",
    components: {
        Payment,
    },
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        list: {
            type: Array, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => [], // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                num: [],
                time: [],
                pay: 1,
                price: 15,
            },
            payment: {
                show: false,
                refresh: false,
                info: {},
            },
            total: 0,
        };
    },
    computed: {
        totalPrice() {
            let total = 0;
            for (const i of this.form.num) {
                total += i * 15;
            }
            return total || 0;
        },
    },
    created() {},
    methods: {
        commit() {
            const arr = [];
            for (const i of this.form.num) {
                if (this.$textNull(i)) {
                    this.$message.error("请选择课时数量！！！");
                    return;
                }
            }
            if (this.form.time.length < this.list.length) {
                this.$message.error("请选择生效时间！！！");
                return;
            }
            // this.getPay()
            for (const i in this.form.time) {
                arr.push({
                    entryIntoForce: this.form.time[i],
                    cultivateType: this.list[i].cultivate_type,
                    companyId:
                        this.$loginInfo.company_id == 0
                            ? null
                            : this.$loginInfo.company_id,
                    createId: this.$loginInfo.master_id,
                    courseNum: this.form.num[i],
                    surplus: this.form.num[i],
                    totalPrice: this.form.num[i] * this.form.price,
                });
            }
            if (this.form.pay === 1) {
                addOrder(
                    {
                        // url:'http://192.168.88.116:9566/#/manageCenter/courseOrder'
                        url:
                            "http://192.168.88.158:9890/#/manageCenter/courseOrder",
                        // url: 'https://zdwy.online:9887/#/manageCenter/courseOrder'
                    },
                    arr
                ).then((res) => {
                    const pay = document.getElementById("pay");
                    pay.innerHTML = res.data.body;
                    document.forms[0].setAttribute("target", "_self"); // 新开窗口跳转
                    document.forms[0].submit();
                    // pay.innerHTML=''
                });
            } else {
                addOrderWX(null, arr).then((res) => {
                    //console.log(res)
                    // pay.innerHTML=''
                    this.payment.show = true;
                    this.payment.refresh = true;
                    this.payment.info.imgUrl = res.data;
                });
            }
        },
        getPay() {
            getPayForm().then((res) => {
                // this.result.form=res.data.body
                const pay = document.getElementById("pay");
                pay.innerHTML = res.data.body;
                document.forms[0].setAttribute("target", "_blank"); // 新开窗口跳转
                document.forms[0].submit();
                pay.innerHTML = "";
            });
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
        refreshDialog() {
            this.payment.refresh = false;
        },
    },
};
</script>

<style scoped>
.text-width {
    width: 70px;
    display: inline-block;
}
.text-width-big {
    width: 120px;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
}
</style>
