<template>
  <div class="dashboard-container">
    <Operate
      v-if="dialog.refresh"
      :dialog.sync="dialog.show"
      :row="dialog.row"
      :info="dialog.info"
      @get-list="getList"
      @refresh="refreshDialog"
    />
    <Receipt
      v-if="dialog.refresh2"
      :dialog.sync="dialog.show2"
      @refresh="refreshDialog"
    />
    <el-card>
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col>
          <span class="text-primary text-pad-right">日期</span>
          <el-date-picker
            v-model="condition.date"
            type="daterange"
            class="input searchInput"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            :unlink-panels="true"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            size="mini"
            @change="changeCondition"
          />
        </el-col>
        <!--        <el-col>-->
        <!--          <el-input v-model="condition.search" class="input searchInput" size="mini" suffix-icon="el-icon-search"-->
        <!--                    placeholder="搜索" @input="changeCondition"/>-->
        <!--        </el-col>-->
      </el-row>
    </el-card>
    <el-card style="margin-top: 20px;">
      <el-row>
        <vxe-toolbar>
          <template v-slot:buttons>
            <el-row type="flex" justify="between">
              <el-col>
                <el-button v-if="$loginInfo.role_g_id!=4" type="primary" size="mini" @click="openDialog({title:'购买课程',addOr:'add'})">购买课程</el-button>
                <el-button type="danger" size="mini" @click="openReceipt({del:'batch'})">开发票</el-button>
              </el-col>
              <el-col :span="1">
                <vxe-button @click="getList">刷新</vxe-button>
              </el-col>
            </el-row>
          </template>
        </vxe-toolbar>
        <vxe-table
          ref="table"
          border
          resizable
          :auto-resize="true"
          :header-row-style="headerStyle"
          :row-style="rowStyle"
          align="center"
          class="vxe-table-element"
          height="600"
          row-id="notice_id"
          :sort-config="{remote: true}"
          :filter-config="{remote: true}"
          :checkbox-config="{reserve: true}"
          :data="table.list"
          :loading="loading"
        >
          <vxe-table-column type="seq" width="60" />
          <vxe-table-column
            title="课程类型"
          >
            <template v-slot="{row}">
              <!--              <span v-if="row.cultivateType===1">岗前培训</span>-->
              <!--              <span v-else-if="row.cultivateType===2" class="text-success">安全培训</span>-->
              <!--              <span v-else-if="row.cultivateType===3" class="text-success">继续教育</span>-->
              <span>安全培训</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="createTime"
            title="下单时间"
            formatter="toDateString"
          />
          <vxe-table-column
            field="entryIntoForce"
            title="课程生效时间"
            formatter="toDateString"
          />
          <vxe-table-column
            field="courseNum"
            title="购买数量（课时）"
          />
          <vxe-table-column
            field="surplus"
            title="剩余课时"
          />
          <vxe-table-column
            field="courseStatus"
            title="状态"
          />
          <!--          <vxe-table-column-->
          <!--            field=""-->
          <!--            title="操作"-->
          <!--          >-->
          <!--            <template v-slot="{row}">-->
          <!--              <span v-if="row.faqList.length>0">{{row.faqList[0].content}}</span>-->
          <!--              <el-button v-else>回复</el-button>-->
          <!--            </template>-->
          <!--          </vxe-table-column>-->
        </vxe-table>
        <div style="height: 20px" />
        <el-pagination
          background
          :current-page="table.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100]"
          :page-size="table.pageSize"
          layout="total, ->, sizes, prev, pager, next, jumper"
          :total="table.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
import { getOrderList } from '@/api/courseOrder'
import Operate from './Operate'
import Receipt from './Receipt'
export default {
  name: 'Index',
  components: { Operate, Receipt },
  data() {
    return {
      loading: false,
      condition: {
        is_release: 2,
        search: '',
        date: [],
        start: '',
        end: ''
      },
      table: {
        list: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        sortName: '',
        sortBy: ''
      },
      dialog: {
        row: {},
        info: {},
        refresh: false, // 每次刷新DOM
        refresh2: false, // 每次刷新DOM
        show: false,
        show2: false
      },
      company: [],
      loginInfo: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    rowStyle,
    headerStyle,
    openDialog(info, row) {
      this.dialog.refresh = true
      this.dialog.show = true
      this.dialog.info = info
      this.dialog.row = row
    },
    openReceipt(info, row) {
      this.dialog.refresh2 = true
      this.dialog.show2 = true
      this.dialog.info = info
      this.dialog.row = row
    },
    changeCondition() {
      this.table.currentPage = 1
      this.getList()
    },
    getList() {
      this.loading = true
      getOrderList({
        search: this.condition.search,
        companyId: this.$loginInfo.company_id == 0 ? null : this.$loginInfo.company_id,
        startTime: this.condition.date ? this.condition.date[0] : '',
        endTime: this.condition.date ? this.condition.date[1] : '',
        currentPage: this.table.currentPage,
        pageSize: this.table.pageSize
      }).then(res => {
        if (res.data.code === 1) {
          this.table.list = res.data.content
          this.table.total = Number(res.data.totalElements)
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleSizeChange(pageSize) {
      this.table.pageSize = pageSize
      this.table.currentPage = 1
      this.getList()
    },
    handleCurrentChange(currentPage) {
      this.table.currentPage = currentPage
      this.getList()
    },
    refreshDialog() {
      this.dialog.refresh = false
      this.dialog.refresh2 = false
    }
  }
}
</script>

<style scoped>

</style>
