import request from '@/utils/request'
const src = 'com_video'

// 查询公司视频
export function getVideoList(params) {
  return request({
    url: src + '/allVideo',
    method: 'get',
    params
  })
}

// 添加公司视频
export function addVideo(params, data) {
  return request({
    url: src + '/addVideo',
    method: 'post',
    params: params,
    data
  })
}

// 删除公司视频
export function detVideo(params) {
  return request({
    url: src + '/detVideo',
    method: 'delete',
    params
  })
}

// 获得单个公司视频详情
export function getSoloVideo(params) {
  return request({
    url: src + '/getSoloVideo',
    method: 'get',
    params
  })
}

// 更新公司视频
export function updateCVideo(params, data) {
  return request({
    url: src + '/updateVideo',
    method: 'put',
    params: params,
    data
  })
}

// 查询公司视频添加课程
export function getCVideoForCourse(params) {
  return request({
    url: src + '/name',
    method: 'get',
    params
  })
}
