<template>
  <el-row type="flex" justify="justify-between">
    <el-col>
      <span class="text-primary text-pad-right">日期</span>
      <el-date-picker
        v-model="condition.date"
        type="daterange"
        class="input searchInput"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        size="mini"
        :unlink-panels="true"
        align="center"
        placeholder="选择日期"
        @change="changeDate"
      />
    </el-col>
    <el-col :span="8">
      <el-input
        v-model="condition.search"
        class="input searchInput"
        size="mini"
        suffix-icon="el-icon-search"
        placeholder="搜索"
        @input="searchInput"
      />
    </el-col>
  </el-row>
</template>

<script>
  import { getYear, getMonth } from '@/utils/getDate'
  export default {
    name: 'TotalSearch',
    data() {
      return {
        loading: false,
        condition: {
          date: '',
          search: ''
        }
      }
    },

    created() {
      const year = getYear()
      const month = getMonth()
      this.condition.date = year + '-' + month
      this.$emit('get-date', [year, month])
    },
    methods: {
      changeDate(v) {
        if (v === null) {
          this.$emit('get-start', null)
          this.$emit('get-end', null)
          return
        }
        this.$emit('get-start', v[0] + ' 00:00:00')
        this.$emit('get-end', v[1] + ' 23:59:59')
      },
      searchInput(v) {
        this.$emit('get-search', v)
      }
    }
  }
</script>

<style scoped>

</style>
