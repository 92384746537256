<template>
  <el-dialog
    :visible.sync="show"
    width="40%"
    :append-to-body="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm" :disabled="info.addOr === 'view'">
          <el-form-item label="视频标题" :required="true">
            <el-input v-model="form.video_name" class="formInput" />
          </el-form-item>
          <el-form-item label="视频长度" :required="true" class="formInput">
            <el-input v-model="form.video_time" class="formInput">
              <template slot="append">时长(例如：00:20:15)</template>
            </el-input>
          </el-form-item>
          <!--          <el-form-item label="视频清晰度" :required="true" class="formInput">-->
          <!--            <el-select>-->
          <!--              <el-option>流畅</el-option>-->
          <!--              <el-option>标清</el-option>-->
          <!--              <el-option>高清</el-option>-->
          <!--            </el-select>-->
          <!--            <el-input v-model="form.video_time" class="formInput">-->
          <!--              <template slot="append">时长(例如：00:20:15)</template>-->
          <!--            </el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label="视频路径" :required="true" class="formInput">
            <el-input v-model="form.video_path" class="formInput">
              <template slot="append" />
            </el-input>
          </el-form-item>
          <el-form-item label="视频网络地址（流畅格式）" class="formInput">
            <el-input v-model="form.retrench_video_path" class="formInput" />
          </el-form-item>
          <el-form-item label="视频网络地址（标清格式）" class="formInput">
            <el-input v-model="form.sd_video_path" class="formInput" />
          </el-form-item>
          <el-form-item label="视频网络地址（高清格式）" class="formInput">
            <el-input v-model="form.hd_video_path" class="formInput" />
          </el-form-item>
        </el-form>
        <el-form inline label-width="110px" class="allForm flex justify-between flex-wrap" :disabled="info.addOr === 'view'">
          <el-form-item label="所属分类" :required="true" class="formInput">
            <el-cascader
              v-model="form.category_id"
              :options="category"
              :props="{expandTrigger:'hover',value:'category_id',label:'name',leaf:'category_id',emitPath:false}"
              :show-all-levels="false"
              filterable
              clearable
            />
          </el-form-item>
          <el-form-item label="授课老师" :required="true" class="formInput">
            <el-input v-model="form.teacher_name" class="formInput" />
          </el-form-item>
        </el-form>
        <el-form ref="ruleForm" label-width="110px" class="allForm" :disabled="info.addOr === 'view'">
          <el-form-item label="视频简介" :required="true" class="formInput">
            <el-input v-model="form.introduce" class="formInput" type="textarea" :autosize="{ minRows: 4}" />
          </el-form-item>
          <el-form-item label="视频封面" :required="true">
            <el-upload
              ref="upload"
              class="upload-demo"
              drag
              action="#"
              list-type="picture"
              :file-list="form.fileList"
              :auto-upload="false"
              :limit="1"
              :on-change="change"
            >
              <i class="el-icon-receiving" style="font-size: 80px;" />
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>，只支持传一张图片</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div v-if="info.addOr!=='view'" slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addVideo, updateVideo } from '@/api/video'
  import { uploadAccept } from '@/utils/uploadAccept'
  import { getName } from '@/api/category'
  import { transData } from '@/utils/handleJson'
  export default {
    name: 'Operate',
    components: {
    },
    props: {
      // 打开dialog的
      dialog: {
        type: Boolean, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: false // 这样可以指定默认的值
      },
      info: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      },
      row: {
        type: Object, // 指定传入的类型
        // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
        default: () => {} // 这样可以指定默认的值
      }
    },
    data() {
      return {
        show: this.dialog,
        form: {
          video_name: '',
          video_time: '',
          video_path: '',
          retrench_video_path: '',
          sd_video_path: '',
          hd_video_path: '',
          teacher_name: '',
          introduce: '',
          category_id: '',
          fileList: [],
          file: ''
        },
        category: []
      }
    },
    created() {
      this.upd()
      this.getCategory()
    },
    methods: {
      upd() {
        if (this.info.addOr !== 'add') {
          this.form.video_name = this.row.video_name
          this.form.video_path = this.row.video_path
          this.form.teacher_name = this.row.teacher_name
          this.form.video_time = this.row.video_time
          this.form.retrench_video_path = this.row.retrench_video_path
          this.form.sd_video_path = this.row.sd_video_path
          this.form.hd_video_path = this.row.hd_video_path
          this.form.introduce = this.row.introduce
          this.form.file = this.row.video_img
          this.$nextTick(() => {
            this.form.category_id = this.row.category_id
          })
          this.form.fileList.push({ url: this.$srcUrl + this.row.video_img, name: this.$srcUrl + this.row.video_img })
        }
      },
      getCategory() {
        getName().then(res => {
          if (res.data.code === 1) {
            this.category = transData(res.data.content, 'category_id', 'p_id', 'children')
          }
        })
      },
      change(file, fileList) {
        const fileSize = file.size / 1024 / 1024 < 500
        if (!fileSize) {
          this.$message.error('文件大小不能超过500MB！！！')
          return false
        }
        let flag = false
        flag = uploadAccept(file, '图片')
        if (flag) {
          this.form.file = file
        } else {
          fileList.splice(-1, 1)
        }
      },
      // 提交添加
      commit() {
        if (this.$textNull(this.form.video_name)) {
          this.$message.error('请填写视频标题！')
          return
        } else if (this.$textNull(this.form.video_time)) {
          this.$message.error('请填写视频时间！')
          return
        } else if (this.$textNull(this.form.category_id)) {
          this.$message.error('请选择视频分类！')
          return
        } else if (this.$textNull(this.form.video_path)) {
          this.$message.error('请填写视频地址！')
          return
        } else if (this.$textNull(this.form.teacher_name)) {
          this.$message.error('请填写授课老师！')
          return
        } else if (this.$textNull(this.form.introduce)) {
          this.$message.error('请填写视频简介！')
          return
        } else if (this.$textNull(this.form.file)) {
          this.$message.error('请选择视频封面！')
          return
        }
        const loading = this.$loading({
          lock: true,
          text: '正在添加数据请稍后！！！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        const form = new FormData()
        form.append('video_name', this.form.video_name)
        form.append('video_time', this.form.video_time)
        form.append('video_path', this.form.video_path)
        form.append('teacher_name', this.form.teacher_name)
        form.append('introduce', this.form.introduce)
        form.append('category_id', this.form.category_id)
        form.append('myFile', this.form.file.raw)
        form.append('retrench_video_path', this.form.retrench_video_path)
        form.append('sd_video_path', this.form.sd_video_path)
        form.append('hd_video_path', this.form.hd_video_path)
        if (this.info.addOr === 'add') {
          addVideo(form).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.close()
              this.$emit('get-list')
            } else {
              this.$message.error(res.data.msg)
            }
            loading.close()
          })
        } else {
          form.append('video_id', this.row.video_id)
          updateVideo(form).then(res => {
            if (res.data.code === 1) {
              this.$message.success(res.data.msg)
              this.close()
              this.$emit('get-list')
            } else {
              this.$message.error(res.data.msg)
            }
            loading.close()
          })
        }
      },
      // 关闭模态框
      close() {
        this.show = false
        this.$emit('refresh')
      }
    }
  }
</script>

<style scoped>

</style>
