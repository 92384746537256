<template>
  <div class="dashboard-container" style="">
    <el-tabs v-model="activeName">
      <el-tab-pane label="标准课程" name="标准课程" />
      <el-tab-pane label="企业培训" name="企业培训" />
    </el-tabs>
    <Standard v-if="activeName==='标准课程'" />
    <Company v-else-if="activeName==='企业培训'" />
  </div>
</template>

<script>
import Standard from './standardType'
import Company from './companyType'

export default {
  name: 'Index',
  components: {
    Standard, Company
  },
  data() {
    return {
      activeName: '标准课程'
    }
  }
}
</script>

<style scoped>

</style>
