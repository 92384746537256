<template>
    <div class="dashboard-container">
        <!--    <Operate-->
        <!--      v-if="dialog.refresh"-->
        <!--      :dialog.sync="dialog.show"-->
        <!--      :row="dialog.row"-->
        <!--      :info="dialog.info"-->
        <!--      @get-list="getList"-->
        <!--      @refresh="refreshDialog"-->
        <!--    />-->
        <el-card>
            <el-row :gutter="50">
                <el-col :span="6">
                    <span class="text-primary text-pad-right">日期</span>
                    <el-date-picker
                        v-model="condition.date"
                        type="month"
                        class="input searchInput"
                        value-format="yyyy-MM-dd"
                        size="mini"
                        @change="changeTime"
                    />
                </el-col>
                <el-col :span="6">
                    <span class="text-primary text-pad-right">课程名称</span>
                    <el-input
                        v-model="condition.search"
                        class="searchInput"
                        style="width: 200px"
                        size="mini"
                        suffix-icon="el-icon-search"
                        placeholder="搜索"
                        @input="searchInput"
                    />
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col :span="1">
                                <vxe-button @click="getList">刷新</vxe-button>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="600"
                    row-id="ID"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                >
                    <vxe-table-column type="checkbox" width="60" fixed="left" />
                    <vxe-table-column type="seq" width="60" />
                    <vxe-table-column field="title" title="课程名称" />
                    <vxe-table-column title="课程类型">
                        <template>
                            <span>安全教育</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="entry_time" title="生效时间">
                    </vxe-table-column>
                    <vxe-table-column field="failure_time" title="失效时间">
                    </vxe-table-column>
                    <vxe-table-column field="train_hours" title="课程时长">
                    </vxe-table-column>
                    <vxe-table-column field="" title="操作">
                        <template v-slot="{ row, rowIndex }">
                            <el-dropdown size="small" type="primary">
                                <el-button size="small" type="primary">
                                    操作<i
                                        class="el-icon-arrow-down el-icon--right"
                                    />
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <router-link
                                        :to="{
                                            name: 'ViewCourse/Operate',
                                            query: {
                                                course_id: row.course_id,
                                                content: row.content,
                                            },
                                        }"
                                    >
                                        <el-dropdown-item
                                            v-if="row.is_release === 1"
                                            icon="el-icon-edit"
                                            @click.native="
                                                openDialog(
                                                    {
                                                        title: '查看',
                                                        addOr: 'view',
                                                    },
                                                    row
                                                )
                                            "
                                            >查看
                                        </el-dropdown-item>
                                    </router-link>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { getCourseByCompanyId } from "@/api/course";
import { getMonth, getYear } from "@/utils/getDate";
// import Operate from './Operate'
export default {
    name: "Index",
    components: {
        // Operate
    },
    data() {
        return {
            loading: false,
            condition: {
                is_release: 2,
                search: "",
                date: "",
                start: "",
                end: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            company: [],
            loginInfo: {},
        };
    },
    created() {
        const date = new Date();
        const year = getYear(date);
        const month = getMonth(date) + 1;
        this.condition.date = year + "-" + month;
        this.changeTime(this.condition.date);
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        searchInput(v) {
            this.table.currentPage = 1;
            this.getList();
        },
        changeRelease() {
            this.table.currentPage = 1;
            this.getList();
        },
        changeTime(v) {
            this.table.currentPage = 1;
            if (v === null) {
                // this.condition
            } else {
                const date = this.condition.date.split("-");
                this.condition.start = date[0];
                this.condition.end = date[1];
            }
            this.getList();
        },
        getList() {
            this.loading = true;
            getCourseByCompanyId({
                companyId: this.$loginInfo.company_id,
                search: this.condition.search,
                year: this.condition.start,
                month: this.condition.end,
            })
                .then((res) => {
                    if (res.data.code === 1) {
                        this.table.list = res.data.content;
                        this.table.total = Number(res.data.totalElements);
                    }
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        refreshDialog() {
            this.dialog.refresh = false;
        },
    },
};
</script>

<style scoped></style>
