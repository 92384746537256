<template>
  <el-row type="flex" justify="justify-between">
    <el-col :span="8">
      <el-input
        v-model="condition.search"
        class="input searchInput"
        size="mini"
        suffix-icon="el-icon-search"
        placeholder="搜索"
        @input="searchInput"
      />
    </el-col>
  </el-row>
</template>

<script>
  import { getCompanyByRegion } from '@/api/company'
  import { getYear, getMonth } from '@/utils/getDate'
  export default {
    name: 'UserSearch',
    data() {
      return {
        loading: false,
        condition: {
          company: {},
          date: '',
          search: '',
          train: 1
        },
        company: []
      }
    },

    created() {
      this.getCompanyByRegion()
      const year = getYear()
      const month = getMonth()
      this.condition.date = year + '-' + month
      this.$emit('get-date', [year, month])
    },
    methods: {
      getCompanyByRegion() {
        getCompanyByRegion({
          region_id: this.$loginInfo.region_id,
          role_g_id: this.$loginInfo.role_g_id,
          region_level: this.$loginInfo.region_level
        }).then(res => {
          if (res.data.code === 1) {
            this.company = res.data.content
          }
        })
      },
      changeCompany(v) {
        this.$emit('get-company', v)
      },
      changeDate(v) {
        const d = v.split('-')
        this.$emit('get-date', d)
      },
      changeTrain(v) {
        this.$emit('get-train', v)
      },
      searchInput(v) {
        this.$emit('get-search', v)
      }
    }
  }
</script>

<style scoped>

</style>
