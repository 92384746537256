<template>
  <el-row>
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button @click="getList">刷新</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      border
      resizable
      :auto-resize="true"
      :header-row-style="headerStyle"
      :row-style="rowStyle"
      align="center"
      class="vxe-table-element"
      height="600"
      :expand-config="{toggleMethod: expandTable,iconOpen:'el-icon-minus',iconClose:'el-icon-plus',accordion: true}"
      :data="table.list"
      :loading="loading"
    >
      <vxe-table-column type="seq" width="60" />
      <vxe-table-column type="expand" width="60">
        <template v-slot:content="{ row, rowIndex }">
          <vxe-toolbar>
            <template v-slot:buttons>
              <el-row type="flex">
                <el-col>
                  <vxe-button @click="getUserNotice(table2.row)">刷新</vxe-button>
                </el-col>
                <el-col>
                  <el-input
                    v-model="search"
                    class="input searchInput"
                    size="mini"
                    suffix-icon="el-icon-search"
                    placeholder="搜索"
                    @input="searchInput"
                  />
                </el-col>
              </el-row>
            </template>
          </vxe-toolbar>
          <vxe-table
            border
            :header-row-style="headerStyle"
            :row-style="rowStyle"
            :loading="loading2"
            :data="table2.list"
          >
            <vxe-table-column type="seq" width="60" />
            <vxe-table-column
              field="release_time"
              title="发布时间"
              formatter="toDateString"
            />
            <vxe-table-column
              field="title"
              title="公告标题"
            />
            <vxe-table-column
              field="is_read"
              title="是否阅读"
            >
              <template v-slot="{row}">
                <span v-if="row.is_read===1" class="text-success">已读</span>
                <span v-else class="text-danger">未读</span>
              </template>
            </vxe-table-column>
          </vxe-table>
          <el-pagination
            background
            :current-page="table2.currentPage"
            :page-sizes="[5, 10, 15, 20, 50, 100]"
            :page-size="table2.pageSize"
            layout="total, ->, sizes, prev, pager, next, jumper"
            :total="table2.total"
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
          />
        </template>
      </vxe-table-column>

      <vxe-table-column
        field="user_name"
        title="姓名"
      />
      <vxe-table-column
        field="job_name"
        title="岗位"
      />
      <vxe-table-column
        field="dept_name"
        title="部门"
      />
      <vxe-table-column
        field="id_card"
        title="身份证号"
      />
      <vxe-table-column
        field="telephone"
        title="联系电话"
      />
      <vxe-table-column
        field="is_read"
        title="签名"
      >
        <template v-slot="{row}">
          <img src="" alt="" style="width: 50px;height: 50px;">
        </template>
      </vxe-table-column>
    </vxe-table>
    <div style="height: 20px" />
    <el-pagination
      background
      :current-page="table.currentPage"
      :page-sizes="[5, 10, 15, 20, 50, 100]"
      :page-size="table.pageSize"
      layout="total, ->, sizes, prev, pager, next, jumper"
      :total="table.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-row>
</template>

<script>
  import { rowStyle, headerStyle } from '@/utils/tableStyleJs'
  import { getNoticeAllUser, getUserNotice } from '@/api/notice'

  export default {
    name: 'Index',
    components: {},
    props: {
      condition: ''
    },
    data() {
      return {
        loading: false,
        loading2: false,
        table: {
          list: [],
          total: 0,
          currentPage: 1,
          pageSize: 10,
          sortName: '',
          sortBy: ''
        },
        table2: {
          list: [],
          total: 0,
          currentPage: 1,
          pageSize: 10,
          sortName: '',
          sortBy: '',
          row: {}
        },
        search: ''
      }
    },
    watch: {
      condition: {
        handler(v) {
          this.table.currentPage = 1
          this.getList()
        },
        deep: true
      }
    },
    created() {
      this.getList()
    },
    methods: {
      rowStyle,
      headerStyle,
      expandTable({ expanded, row }) {
        if (expanded) {
          this.table2.row = row
          this.getUserNotice(row)
        }
        return true
      },
      getUserNotice(row) {
        this.loading2 = true
        getUserNotice({
          user_id: row.user_id,
          searchName: this.search,
          currentPage: this.table2.currentPage,
          pageSize: this.table2.pageSize
        }).then(res => {
          if (res.data.code === 1) {
            this.table2.list = res.data.content
            this.table2.total = Number(res.data.totalElements)
          }
          this.loading2 = false
        }).catch(e => {
          this.loading2 = false
        })
      },
      searchInput() {
        this.table2.currentPage = 1
        this.getUserNotice(this.table2.row)
      },
      getList() {
        this.loading = true
        getNoticeAllUser({
          region_id: this.$loginInfo.region_id,
          region_level: this.$loginInfo.region_level,
          dept_id: this.$loginInfo.dept_id,
          searchName: this.condition.search,
          currentPage: this.table.currentPage,
          pageSize: this.table.pageSize
        }).then(res => {
          if (res.data.code === 1) {
            this.table.list = res.data.content
            this.table.total = Number(res.data.totalElements)
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      },
      handleSizeChange(pageSize) {
        this.table.pageSize = pageSize
        this.table.currentPage = 1
        this.getList()
      },
      handleCurrentChange(currentPage) {
        this.table.currentPage = currentPage
        this.getList()
      },
      handleSizeChange2(pageSize) {
        this.table2.pageSize = pageSize
        this.table2.currentPage = 1
        this.getUserNotice(this.table2.row)
      },
      handleCurrentChange2(currentPage) {
        this.table2.currentPage = currentPage
        this.getUserNotice(this.table2.row)
      }
    }
  }
</script>

<style scoped>

</style>
