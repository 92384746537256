import request from '@/utils/request'
const src = 'order'
// 获取订单列表
export function getOrderList(params, data) {
  return request({
    url: src + '/get',
    method: 'get',
    params,
    data
  })
}
// 添加订单
export function addOrder(params, data) {
  return request({
    url: src + '/add',
    method: 'post',
    params,
    data
  })
}
// 添加订单微信
export function addOrderWX(params, data) {
  return request({
    url: src + '/addWX',
    method: 'post',
    params,
    data
  })
}
// 获取课程列表
export function getCourseList(params, data) {
  return request({
    url: src + '/course',
    method: 'get',
    params,
    data
  })
}
